import React from 'react';
import { formatDate } from 'date-fns';
import { twMerge } from 'tailwind-merge';

const formatSerialNumbers = (serialNumber: number) => {
  return serialNumber.toString().padStart(4, '0');
};

export type ReceiptUIProps = {
  className?: string;
  title: string;
  receiptNumber: number;
  username: string;
  memberId: number;
  artistHandle: string;
  createdAt: string;
  type: 'streaming' | 'pre-save';
};

export const ReceiptUI = ({
  className,
  title,
  receiptNumber,
  username,
  memberId,
  artistHandle,
  createdAt,
  type,
  ref,
}: {
  ref?: React.Ref<HTMLDivElement>;
} & ReceiptUIProps) => {
  const isLongTitle = title.length > 12;
  return (
    <div
      ref={ref}
      className={`relative box-border flex h-[378px] w-[280px] flex-col items-center gap-6 bg-receipt-paper bg-cover px-4 pt-6 font-plex-mono uppercase ${className}`}
    >
      <p
        className={twMerge(
          'line-clamp-2 w-full overflow-hidden text-ellipsis text-center text-[34px]/[40px] font-bold text-vault_accent_text/80',
          isLongTitle ? 'text-[34px]/[38px] ' : 'text-[38px]/[40px] ',
        )}
      >
        {title}
      </p>
      <div className="flex w-full flex-col items-start text-[13px] text-vault_accent_text/80 ">
        <div className="flex flex-col">
          <p>Receipt #{formatSerialNumbers(receiptNumber)}</p>
          <p> {formatDate(createdAt, 'mm-dd-yy, p')}</p>
        </div>
        <hr className="my-2 h-[1px] w-full border-0 bg-vault_accent_text" />
        <div className="flex w-full flex-col">
          <div className="flex w-full justify-between font-bold text-vault_accent_text/90">
            <p>Item</p>
            <p>Qty</p>
          </div>

          <div className="flex w-full justify-between  ">
            <p>{type}</p>
            <p>1</p>
          </div>
        </div>
        <hr className="my-2 h-[1px] w-full border-0 bg-vault_accent_text" />
        <div className="flex w-full flex-col pt-4 text-vault_accent_text/80">
          <div className="flex flex-col">
            <p className="overflow-hidden text-ellipsis whitespace-nowrap">Name: {username}</p>
            <p>Member Id: {formatSerialNumbers(memberId)}</p>
          </div>
        </div>
      </div>

      <div className="mb-4 mt-auto flex h-12 w-full flex-col items-center gap-2 text-vault_accent_text/80">
        <div className="h-12 w-full bg-receipt-barcode bg-contain" />
        <p className="text-[13px]">{artistHandle}.vault.fm</p>
      </div>

      <div className="absolute left-0 h-[88%] w-full bg-receipt-wrinkle bg-cover opacity-40" />
    </div>
  );
};
