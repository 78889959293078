import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useArtistReleaseCampaigns } from '../../hooks/membership/useArtistReleaseCampaigns';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { EventItem, EventItemSkeleton } from './EventItem';
import { Item } from './shared';

export const EventsSection = ({ artistHandle }: { artistHandle: string }) => {
  const { releaseCamapigns: events = [], isInitialLoading } = useArtistReleaseCampaigns({
    artistHandle,
    limit: 4,
    sort: 'ASC',
    source: 'membership_page',
  });

  const { loggedInUser } = useAuthContext();

  const isOwner =
    (!!artistHandle &&
      loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle))) ??
    false;

  const hasEvents = events.length > 0;
  const hasMoreEvents = events.length > 3;

  if (isInitialLoading) {
    return (
      <Item header={isOwner ? 'Events' : 'New events'}>
        {Array.from({ length: 3 }).map((_, index) => (
          <EventItemSkeleton key={index} />
        ))}
      </Item>
    );
  }

  if (!hasEvents) {
    return null;
  }

  return (
    <Item
      header={isOwner ? 'Events' : 'New events'}
      headerLink={
        hasMoreEvents ? (
          <Link
            to={artistNavigationPath(artistHandle, '/membership/events')}
            className="text-base text-[14px]/[16px] text-vault_text/50 no-underline transition-all duration-200 ease-in hover:text-vault_text/80"
          >
            See all
          </Link>
        ) : null
      }
    >
      {events.slice(0, 3).map(event => (
        <EventItem key={event.id} eventData={event} />
      ))}
    </Item>
  );
};
