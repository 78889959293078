import type { FC } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';

type Props = {
  title: string;
  subtitle?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonHref?: string;
  className?: string;
  icon?: IconProp;
  iconClassName?: string;
  image?: string;
  imageClassName?: string;
  buttonClassName?: string;
  subtitleClassName?: string;
  withVaultTheme?: boolean;
};

export const EmptyStateView: FC<Props> = ({
  title,
  subtitle,
  buttonText,
  onButtonClick,
  buttonHref,
  className,
  icon,
  iconClassName,
  image,
  imageClassName,
  buttonClassName,
  subtitleClassName,
  withVaultTheme = false,
}) => {
  return (
    <View
      className={twMerge(
        'flex flex-1 flex-col items-center justify-center px-16 text-center',
        className,
      )}
    >
      {icon && <FontAwesomeIcon icon={icon} size="2x" className={twMerge('mb-2', iconClassName)} />}
      {image && <Image src={image} alt="Empty state" className={twMerge('mb-2', imageClassName)} />}
      <Text className={twMerge('font-title text-[22px]', withVaultTheme && 'text-vault_text')}>
        {title}
      </Text>
      <Text
        className={twMerge(
          'mt-2 font-normal text-neutral400',
          withVaultTheme && 'text-vault_text opacity-50',
          subtitleClassName,
        )}
      >
        {subtitle}
      </Text>
      <View className="mt-4 flex w-full flex-row justify-center">
        {buttonText && (onButtonClick || buttonHref) && (
          <Button
            type={withVaultTheme ? 'primary-themed' : 'primary'}
            label={buttonText}
            onClick={onButtonClick}
            href={buttonHref}
            event={{ type: EVENTS.EMPTY_VIEW, properties: null }}
            className={buttonClassName}
          />
        )}
      </View>
    </View>
  );
};
