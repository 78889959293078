import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'date-fns';
import millify from 'millify';
import { Link } from 'react-router-dom';
import {
  faFloppyDisk,
  faReceipt,
  faWaveform,
  faWaveformLines,
} from '@soundxyz/font-awesome/pro-light-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { EventItemFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Image } from '../common/Image';

gql(/* GraphQL */ `
  fragment EventItem on ReleaseCampaign {
    id
    __typename
    title
    releaseDate
    linkValue
    status
    presavesEnabled
    initialReleaseImageUrl
    coverImage {
      id
      url
    }
    artist {
      id
      isAuthUserAdmin
      linkValue
    }
    receiptCount
  }
`);

export const EventItem = ({ eventData }: { eventData: FragmentType<EventItemFragmentDoc> }) => {
  const {
    id,
    title,
    coverImage,
    releaseDate,
    linkValue,
    presavesEnabled,
    initialReleaseImageUrl,
    artist: { isAuthUserAdmin, linkValue: artistHandle },
    receiptCount,
  } = getFragment(EventItemFragmentDoc, eventData);

  const releaseDateFormatted = !!releaseDate
    ? `${formatDate(releaseDate, 'ccc, MMM d · p')} `
    : null;

  const releaseImage = coverImage?.url ?? initialReleaseImageUrl;
  return (
    <Link
      to={`${artistNavigationPath(
        artistHandle,
        isAuthUserAdmin ? `/campaign/insights/${id}` : `/s/${linkValue}`,
      )}`}
      className="flex w-full flex-row items-center justify-between no-underline transition-all duration-200 ease-in hover:opacity-80"
    >
      <View className="flex h-16 justify-start gap-4">
        {!!releaseImage ? (
          <Image
            src={releaseImage}
            alt={title}
            className="aspect-square h-16 w-16 flex-shrink-0 rounded-md bg-neutral500"
          />
        ) : (
          <View className="flex aspect-square w-16 flex-shrink-0 items-center justify-center rounded-md  outline outline-1 outline-vault_text/10">
            <FontAwesomeIcon icon={faWaveformLines} className="text-[20px] text-vault_text" />
          </View>
        )}
        <View className="flex flex-col justify-center gap-2">
          <Text className="max-w-56 flex-shrink overflow-hidden text-ellipsis whitespace-nowrap font-title text-[18px]/[20px] font-medium text-vault_text">
            {title}
          </Text>
          <View className="flex flex-col gap-0.5">
            <span className="flex items-center gap-1 text-[14px]/[16px] font-normal">
              {presavesEnabled ? (
                <>
                  <FontAwesomeIcon icon={faFloppyDisk} className=" text-vault_text/60" />
                  <Text className="font-base text-vault_text/60">Pre-save</Text>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faWaveform} className=" text-vault_text/60" />
                  <Text className="font-base text-vault_text/60">Streaming</Text>
                </>
              )}
            </span>
            {!!releaseDateFormatted && (
              <Text className="text-[12px] text-vault_text/50">{releaseDateFormatted}</Text>
            )}
          </View>
        </View>
      </View>
      {isAuthUserAdmin && (
        <View className="flex w-12 flex-row items-center justify-between gap-1 text-[14px] font-normal text-vault_text opacity-60">
          <FontAwesomeIcon icon={faReceipt} className="text-[14px]" />
          <View className="line-clamp-1 flex flex-shrink flex-row justify-end text-[14px]">
            <Text>{millify(receiptCount, { precision: 1, lowercase: true })}</Text>
          </View>
        </View>
      )}
    </Link>
  );
};

export const EventItemSkeleton = () => (
  <View className="flex h-20 w-full items-center justify-between gap-3">
    <View className="flex h-16 w-full justify-between gap-4">
      <View className="flex  aspect-square w-16 flex-shrink-0 animate-pulse items-center justify-center rounded-md bg-vault_text/10" />
      <View className="flex w-full flex-col justify-center gap-2 ">
        <View className="h-5 w-[38%] animate-pulse rounded-sm bg-vault_text/10" />
        <View className="flex flex-col gap-1">
          <View className="h-3 w-[20%] animate-pulse rounded-sm bg-vault_text/10" />
          <View className="h-2 w-[22%] animate-pulse rounded-sm bg-vault_text/10" />
        </View>
      </View>
    </View>
  </View>
);
