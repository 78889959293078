import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { getDSPColor, getDSPIcon } from './helpers';
import { type CampaignType, type DSPInfo } from './schema';

export const DSPRow = ({
  dspInfo,
  type,
  isPreview,
}: {
  dspInfo: DSPInfo;
  type: CampaignType;
  isPreview: boolean;
}) => {
  const icon = getDSPIcon(dspInfo.key);
  const color = getDSPColor(dspInfo.key);

  const isPresave = type == 'presave';

  const onClick = () => {
    if (!dspInfo.uri || isPreview) return;

    // eslint-disable-next-line no-console
    console.log('Presave with uri:', dspInfo.uri);
  };

  return (
    <View className="flex flex-row justify-between">
      <View className="flex flex-row items-center gap-3">
        <FontAwesomeIcon className="h-6 w-6" icon={icon} color={color} />
        <Text className="text-base-l font-semibold text-white">{dspInfo.name}</Text>
      </View>
      <Button
        className="min-w-[92px] rounded-full bg-white/20 px-4 py-2.5 font-title text-[14px] font-medium text-white hover:bg-white/30"
        label={dspInfo.buttonText}
        href={!isPresave && !isPreview ? dspInfo.uri : undefined}
        isExternal={!isPresave}
        onClick={isPresave ? onClick : undefined}
      />
    </View>
  );
};
