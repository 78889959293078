import { type LegacyRef, useMemo, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useGate } from 'statsig-react';

import { useLongPress } from 'use-long-press';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFileDownload } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { FEATURE_GATES } from '../../../../constants/flagConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { MediaQuery } from '../../../../contexts/mediaQuery';
import {
  FeatureTypename,
  type FragmentType,
  getFragment,
  TierTypename,
  TrackItemFragmentDoc,
} from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { LoginStatus } from '../../../../types/authTypes';
import { generateShareLink } from '../../../../utils/linkUtils';
import { TrackItem } from '../Track';
import { type ContentOption, ContentOptions } from '../artist/ContentOptions';
import { useTrackDownloadPress } from '../useTrackDownloadPress';
import { useTrackFilePress } from '../useTrackFilePress';

export const UserTrack = ({
  track,
  containerRef,
  artistHandle,
}: {
  track: FragmentType<TrackItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const { md2 } = MediaQuery.useContainer();
  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    setIsOpen(true);
  })();

  const [menuHovered, setMenuHovered] = useState(false);

  const {
    id: trackId,
    vault,
    parentVaultContentId,
    linkValue,
    downloadEnabled,
    featureAccess,
  } = getFragment(TrackItemFragmentDoc, track);
  const { loginStatus, loggedInUser } = useAuthContext();

  const { value: trackDownloadsFeatureEnabled } = useGate(FEATURE_GATES.TRACK_DOWNLOADS);

  const link = useMemo(() => {
    const path = linkValue ? `/t/${linkValue}` : `/${trackId}`;
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [linkValue, trackId, artistHandle, loggedInUser?.inviteCode]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Track link copied to clipboard!',
  });

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = !isOwner && activeSubscriptionFeatures?.tier == null;
  const hasFreeAccess = featureAccess.some(
    access => access.feature.__typename === FeatureTypename.FreeVaultContent,
  );
  const downloadEnabledForUser =
    downloadEnabled && (hasFreeAccess || activeSubscriptionFeatures?.tier == TierTypename.PaidTier);

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const { onPlayClick } = useTrackFilePress({
    vaultId: vault.id,
    trackId,
    isLocked,
    showUpsellInterstitial,
    folderId: parentVaultContentId,
    artistHandle,
    vaultArtistProfileImage: vault.artist?.profileImage?.url ?? '',
    hasActiveSubscription: !!vault.activeSubscription?.id,
  });

  const { onDownloadClick } = useTrackDownloadPress({
    trackContentId: trackId,
  });

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
  ];

  if (downloadEnabledForUser && trackDownloadsFeatureEnabled) {
    buttons.push({
      title: 'Download',
      icon: faFileDownload,
      onClick: onDownloadClick,
    });
  }

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={false}
      onLongPress={onLongPress}
      onClick={() => {
        if (!isOpen) {
          return onPlayClick();
        }
      }}
      selectedFile={false}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={<TrackItem track={track} containerRef={containerRef} hasEllipsis={md2} />}
      disabled={false}
      disableHover={false}
    />
  );
};
