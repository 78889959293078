import { createContext, useContext } from 'react';
import type { PlayerDevice } from 'react-spotify-web-playback-sdk';

export type SpotifyContextType = {
  isPlaybackReady: boolean;
  player: Spotify.Player | null;
  device: PlayerDevice | null;
  playbackState: Spotify.PlaybackState | null;
  activeCampaignId: string | null;
  setActiveCampaignId: (id: string | null) => void;
};

const defaultSpotifyContext: SpotifyContextType = {
  isPlaybackReady: false,
  player: null,
  device: null,
  playbackState: null,
  activeCampaignId: null,
  setActiveCampaignId: () => {},
};

const SpotifyContext = createContext<SpotifyContextType>(defaultSpotifyContext);

const useSpotifyContext = () => useContext(SpotifyContext);

export { SpotifyContext, useSpotifyContext };
