import { useCallback, useMemo } from 'react';
import { compact } from 'lodash-es';
import millify from 'millify';
import { Navigate, useNavigate, useParams } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { faClose, faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPen } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEye } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../components/buttons/BackButton';
import { Button } from '../components/buttons/Button';
import { CampaignSteps } from '../components/campaign/schema';
import { Image } from '../components/common/Image';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../components/loading/LoadingSkeleton';
import { SkeletonUserRow } from '../components/user/UserRow';
import { EmptyStateView } from '../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../constants/bottomsheetConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useBottomsheetContainer } from '../contexts/BottomsheetContext';
import { useInfiniteQuery, useQuery } from '../graphql/client';
import type { UserRowFragmentDoc } from '../graphql/generated';
import { CampaignInsightsDocument, InsightHeaderFragmentDoc } from '../graphql/generated';
import { CampaignByIdDocument, type FragmentType, getFragment } from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useCopy } from '../hooks/useCopy';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { useWindow } from '../hooks/useWindow';
import { LoginStatus } from '../types/authTypes';
import type { ActionBottomsheetProps } from '../types/bottomsheetTypes';
import { generateShareLink } from '../utils/linkUtils';
import { artistNavigationPath } from '../utils/navigationUtils';
import { constructQueryParams } from '../utils/stringUtils';
import { formatDateString } from '../utils/textUtils';
import { MyMemberRow } from './settings/MySubscribersPage';

gql(`
query CampaignById($id: UUID!, $artistHandle: String!) {
  releaseCampaignByIdOrSlug(id: $id, artistHandle: $artistHandle) {
   id
   linkValue
   ...InsightHeader
  }
}

query CampaignInsights($id: UUID!, $artistHandle: String!, $after: String, $first: Int) {
  userArtistMembershipReceipts(artistHandle: $artistHandle, releaseCampaignIds: [$id], after: $after, first: $first) {
   edges {
    cursor
    node {
      id
      createdAt
      user {
        id
        ...userRow
      }
    }
   }
   pageInfo {
    hasNextPage
    endCursor
   }
  }
}

fragment InsightHeader on ReleaseCampaign {
  id
  title
  initialReleaseImageUrl
  coverImage {
    id
    url
  }
  description
  releaseDate
  newSubscriptionCount
  receiptCount
}
`);

const LIMIT = 20;

export function CampaignInsightsPage() {
  const { campaignId } = useParams();
  const { artistHandle } = useArtistHandle();

  const { loggedInUser, loginStatus } = useAuthContext();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const navigate = useNavigate();

  const { isDesktop } = useWindow();

  useVaultTheme();

  const {
    data,
    isLoading: isCampaignLoading,
    isError: isCampaignError,
    refetch: refetchCampaign,
  } = useQuery(CampaignByIdDocument, {
    variables: !!artistHandle &&
      !!campaignId && {
        id: campaignId,
        artistHandle,
      },
    staleTime: 0,
    select: data => data.data.releaseCampaignByIdOrSlug,
  });

  const {
    orderedList: members,
    isError,
    isLoading,
    refetch,
  } = useInfiniteQuery(CampaignInsightsDocument, {
    filterQueryKey: {
      artistHandle,
      campaignId,
    },
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.userArtistMembershipReceipts.pageInfo.hasNextPage && {
          after: data.userArtistMembershipReceipts.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      !!campaignId &&
      (({ pageParam }) => {
        return {
          id: campaignId,
          artistHandle,
          after: pageParam?.after ?? null,
          first: LIMIT,
        };
      }),
    list: ({ userArtistMembershipReceipts }) => {
      return userArtistMembershipReceipts.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });
  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: data?.linkValue ? `/s/${data?.linkValue}` : '/',
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [artistHandle, data?.linkValue, loggedInUser?.inviteCode]);

  const { copy } = useCopy({ successMessage: 'Copied to clipboard', text: link });

  const isOwner =
    !!artistHandle &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const buttons: ActionBottomsheetProps['buttons'] = useMemo(() => {
    return compact([
      campaignId && {
        label: 'Edit',
        leadingIcon: faPen,
        className: 'text-vault_text px-6 py-5 text-[20px]/[20px] justify-start',
        onClick: () => {
          navigate(artistNavigationPath(artistHandle, `/campaign/edit/${campaignId}`));
          closeBottomsheet();
        },
      },
      {
        label: 'Copy link',
        className: 'text-vault_text px-6 py-5 text-[20px]/[20px] justify-start  ',
        leadingIcon: faLink,
        onClick: () => {
          copy();
          closeBottomsheet();
        },
      },
      campaignId && {
        label: 'Preview',
        leadingIcon: faEye,
        className: 'text-vault_text px-6 py-5 text-[20px]/[20px] justify-start',
        onClick: () => {
          const queryParams = constructQueryParams({
            step: CampaignSteps.Preview,
            viewing: true,
          });

          navigate(artistNavigationPath(artistHandle, `/campaign/edit/${campaignId}`, queryParams));
          closeBottomsheet();
        },
      },
    ]);
  }, [artistHandle, campaignId, copy, navigate, closeBottomsheet]);

  const renderItem = useCallback(
    (
      _index: number,
      {
        id,
        createdAt,
        user,
      }: {
        id: string;
        createdAt: string;
        user: { id: string } & FragmentType<UserRowFragmentDoc>;
      },
    ) => {
      return (
        <MyMemberRow
          id={id}
          createdAt={createdAt}
          user={user}
          phone={null}
          email={null}
          geoLocation={null}
          joinedViaReferralCode={null}
          isTrial={null}
          vaultSubscriptionSourceText={null}
          vaultSubscriptionSourceType={null}
          artistMembership={null}
        />
      );
    },
    [],
  );

  const Header = useCallback(() => {
    if (isCampaignLoading || data == null) {
      return <InsightHeaderSkeleton />;
    }

    return <InsightHeader campaignData={data} />;
  }, [data, isCampaignLoading]);

  const EmptyState = useCallback(() => {
    if (isCampaignLoading || isLoading) {
      return (
        <>
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" />
        </>
      );
    }

    if (isCampaignError || isError) {
      return (
        <ErrorView
          onRetryClick={isCampaignError ? refetchCampaign : refetch}
          loggingType="campaign_insights_page_retry"
          withVaultTheme
        />
      );
    }

    return (
      <EmptyStateView
        className="mt-8"
        title="No insights yet"
        subtitle="Share your release campaign to see insights here"
        buttonText="Share campaign"
        onButtonClick={data != null ? copy : undefined}
        withVaultTheme
      />
    );
  }, [
    copy,
    data,
    isCampaignError,
    isCampaignLoading,
    isError,
    isLoading,
    refetch,
    refetchCampaign,
  ]);

  if (loginStatus !== LoginStatus.LOADING && !isOwner) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withBottomNavigator={false}
      vaultId={undefined}
      messageChannelId={undefined}
      hasChatReadAccess={undefined}
      showBorder
      showRoundedTop={false}
      withVaultTheme
      stretch
      headerLeft={<BackButton icon={!isDesktop ? faClose : undefined} />}
      headerCenter={
        <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">Insights</Text>
      }
      headerRight={
        <Button
          label=""
          iconOnly
          leadingIcon={faEllipsis}
          onClick={() => {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ACTION,
              actionBottomsheetProps: {
                buttons,
                withVaultTheme: true,
              },
            });
          }}
          className="text-[24px] text-vault_text"
        />
      }
      isHeaderTransparent
    >
      <Virtuoso
        data={members}
        itemContent={renderItem}
        components={{ Header, EmptyPlaceholder: EmptyState }}
        className="h-full w-full"
      />
    </DefaultLayout>
  );
}

function InsightHeader({ campaignData }: { campaignData: FragmentType<InsightHeaderFragmentDoc> }) {
  const {
    title,
    coverImage,
    initialReleaseImageUrl,
    releaseDate,
    description,
    newSubscriptionCount,
    receiptCount,
  } = getFragment(InsightHeaderFragmentDoc, campaignData);

  const coverImageUrl = coverImage?.url ?? initialReleaseImageUrl;

  return (
    <View className="mt-6 flex flex-col items-start justify-start md2:mt-8">
      <View className="flex flex-col items-start justify-start gap-4">
        <View className="flex flex-row items-center justify-start gap-2">
          <View className="flex h-[56px] w-[56px] items-center justify-center rounded-full bg-vault_text">
            {coverImageUrl && (
              <Image
                src={coverImageUrl}
                alt="Cover Image"
                className="h-full w-full rounded-md object-cover"
              />
            )}
          </View>
          <View className="flex flex-col items-start justify-start gap-1">
            <Text className="line-clamp-1 font-title text-[29px] font-medium text-vault_text">
              {title}
            </Text>
            {releaseDate != null && (
              <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-60">
                {formatDateString({ date: releaseDate, format: 'month_day_year' })}
              </Text>
            )}
          </View>
        </View>
        {!!description && (
          <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-50">
            {description}
          </Text>
        )}
      </View>
      <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(receiptCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total receipts</Text>
        </View>
        <View className="flex flex-1 flex-col gap-3 rounded-md border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(newSubscriptionCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">New signups</Text>
        </View>
      </View>
    </View>
  );
}

function InsightHeaderSkeleton() {
  return (
    <View className="mt-6 flex flex-col items-start justify-start md2:mt-8">
      <View className="flex flex-col items-start justify-start gap-4">
        <View className="flex flex-row items-center justify-start gap-2">
          <LoadingSkeleton className="h-[56px] w-[56px] rounded-full bg-vault_accent opacity-10" />
          <View className="flex flex-col items-start justify-start gap-1">
            <LoadingSkeleton className="h-[35px] w-[200px] rounded-md bg-vault_accent opacity-10" />
            <LoadingSkeleton className="h-[17px] w-[150px] rounded-md bg-vault_accent opacity-10" />
          </View>
        </View>
      </View>
      <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
        <LoadingSkeleton className="flex h-[93px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_accent border-opacity-10 bg-vault_accent opacity-10" />
        <LoadingSkeleton className="flex h-[93px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_accent border-opacity-10 bg-vault_accent opacity-10" />
      </View>
    </View>
  );
}
