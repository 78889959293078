import { type UIEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException, captureMessage } from '@sentry/react';
import { motion } from 'framer-motion';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faApple, faSpotify } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faHardDrive, faMessages } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faChevronRight, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { uuidv4 } from '@soundxyz/utils';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import {
  type ClaimReceiptViewFragment,
  ClaimReceiptViewFragmentDoc,
  type FragmentType,
  getFragment,
  LastMembershipReceiptDocument,
  type MembershipReceiptFragment,
  MembershipReceiptFragmentDoc,
  type MutationReleaseCampaignReportPlayStreamInput,
  ReleaseCampaignContentType,
  ReleaseCampaignReportPlayStreamDocument,
  ThirdPartyPlatform,
} from '../../graphql/generated';
import { useAppleMusicAuth } from '../../hooks/appleMusic/useAppleMusicAuth';
import { usePlayAppleMusic } from '../../hooks/appleMusic/usePlayAppleMusic';
import { usePlaySpotifyTrack } from '../../hooks/spotify/usePlaySpotifyTrack';
import { useSpotifyAuth } from '../../hooks/spotify/useSpotifyAuth';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { extractAppleMusicResourceId, extractSpotifyResourceId } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { AudioPlayer } from '../main/AudioPlayer';
import { ReceiptUI } from '../membership/ReceiptUI';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { MembershipBadge } from '../vault/MembershipBadge';

gql(/* GraphQL */ `
  fragment ClaimReceiptView on ReleaseCampaign {
    id
    title
    linkValue
    spotifyResourceId
    appleMusicResourceId
    contentType
    coverImage {
      id
      url
    }
    initialReleaseImageUrl
    presavesEnabled
    artist {
      id
      name
      linkValue
      profileImage {
        id
        url
      }
    }
    externalLinks {
      id
      url
      platform
    }
  }

  fragment MembershipReceipt on ArtistMembershipReceipt {
    __typename
    id
    createdAt
    membership {
      createdAt
      artist {
        id
        name
        membershipCardImage {
          id
          url
        }
        linkValue
      }
      points
      serialNumber
    }
    user {
      id
      displayName
      username
    }
    serialNumber
  }

  mutation ReleaseCampaignReportPlayStream($input: MutationReportPlayStreamReleaseCampaignInput!) {
    reportPlayStreamReleaseCampaign(input: $input) {
      __typename
      ... on MutationReportPlayStreamReleaseCampaignSuccess {
        data {
          id
          ...MembershipReceipt
        }
      }

      ... on NotAuthorizedError {
        __typename
        message
      }

      ... on NotFoundError {
        __typename
        message
      }
    }
  }

  query LastMembershipReceipt(
    $artistHandle: String
    $receiptType: ArtistMembershipReceiptTypenames
    $releaseCampaignId: UUID!
    $spotifyAuthCode: String
    $appleMusicUserToken: String
  ) {
    lastMembershipReceipt(
      artistHandle: $artistHandle
      receiptType: $receiptType
      releaseCampaignId: $releaseCampaignId
      spotifyAuthCode: $spotifyAuthCode
      appleMusicUserToken: $appleMusicUserToken
    ) {
      ...MembershipReceipt
    }
  }
`);

export function ClaimReceiptView({
  campaignFrag,
  artistData,
  step,
}: {
  campaignFrag: FragmentType<ClaimReceiptViewFragmentDoc>;
  artistData?: {
    membershipImage: string | null | undefined;
    linkValue: string | null | undefined;
    name: string | null | undefined;
  };
  step: string | null;
}) {
  const campaign = getFragment(ClaimReceiptViewFragmentDoc, campaignFrag);

  const coverImageUrl = campaign?.coverImage?.url || campaign?.initialReleaseImageUrl;

  const randomUUID = useMemo(() => uuidv4(), []);

  const [source, setSource] = useState<'apple' | 'spotify' | null>(null);
  const [receiptData, setReceiptData] = useState<MembershipReceiptFragment | null>(null);

  const background = useMemo(() => {
    return (
      <>
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Blurred Track/Album Cover"
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        ) : (
          <UserPlaceholderImage
            id={randomUUID}
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        )}

        <View
          className={twMerge(
            'absolute bottom-0 z-base hidden h-full w-full bg-gradient-to-b from-transparent to-black md2:block',
          )}
        />
        <View
          className={twMerge(
            'absolute bottom-0 z-base h-full w-full bg-gradient-to-b from-transparent via-black/80 to-black md2:hidden',
          )}
        />
      </>
    );
  }, [coverImageUrl, randomUUID]);

  const content = useMemo(() => {
    if (step === 'view')
      return <PlayView campaign={campaign} setReceiptData={setReceiptData} setSource={setSource} />;
    if (step === 'claim')
      return (
        <ClaimView
          campaign={campaign}
          receiptData={receiptData}
          source={source}
          artistData={artistData}
        />
      );

    return null;
  }, [artistData, campaign, receiptData, source, step]);

  return (
    <View className="box-border h-full w-full items-center justify-center overflow-y-hidden overflow-x-clip md2:flex">
      {background}
      {content}
      <View className="absolute bottom-0 left-0 right-0 z-above1">
        <AudioPlayer withBottomNavigator={false} withVaultTheme={false} />
      </View>
    </View>
  );
}

function PlayView({
  campaign,
  setReceiptData,
  setSource,
}: {
  campaign: ClaimReceiptViewFragment;
  setReceiptData: React.Dispatch<React.SetStateAction<MembershipReceiptFragment | null>>;
  setSource: React.Dispatch<React.SetStateAction<'apple' | 'spotify' | null>>;
}) {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { loggedInUser } = useAuthContext();
  const { openToast } = useToast();

  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);
  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);
  const spotifyAuth = useSpotifyAuth({
    presaveEnabled: spotifyConnectEnabled,
  });

  const { mutateAsync: reportPlayStream, isLoading } = useMutation(
    ReleaseCampaignReportPlayStreamDocument,
    {
      retry: 3,
    },
  );

  const isSpotifyAccountConnected =
    !!loggedInUser?.spotifyAuthConnection?.spotifyUserId ||
    spotifyAuth.type === 'already-connected';

  const appleMusicAuth = useAppleMusicAuth({
    appleMusicEnabled: appleMusicConnectEnabled,
  });

  const isAppleMusicAccountConnected =
    !!loggedInUser?.appleMusicAuthConnections.length ||
    appleMusicAuth.type === 'apple-music-already-linked' ||
    appleMusicAuth.type === 'apple-music-connected-without-user';

  const randomUUID = useMemo(() => uuidv4(), []);

  const artistLinkValue = campaign.artist.linkValue;
  const artistName = campaign.artist.name || campaign.artist.linkValue;
  const artistImageUrl = campaign.artist.profileImage?.url;
  const coverImageUrl = campaign.coverImage?.url || campaign.initialReleaseImageUrl;
  const title = campaign.title;

  const spotifyLink = campaign.externalLinks.find(
    link => link.platform === ThirdPartyPlatform.Spotify,
  )?.url;

  const appleMusicLink = campaign.externalLinks.find(
    link => link.platform === ThirdPartyPlatform.AppleMusic,
  )?.url;

  const spotifyResourceId =
    campaign.spotifyResourceId ??
    (spotifyLink != null ? extractSpotifyResourceId(spotifyLink) : null);

  const appleMusicResourceId =
    campaign.appleMusicResourceId ??
    (appleMusicLink != null ? extractAppleMusicResourceId(appleMusicLink) : null);

  const contentType = campaign.contentType;

  const {
    onClick: onClickSpotifyPlay,
    isDisabled: isSpotifyDisabled,
    isLoading: isSpotifyLoading,
  } = usePlaySpotifyTrack({
    spotifyUri:
      contentType === ReleaseCampaignContentType.Track && spotifyResourceId != null
        ? `spotify:track:${spotifyResourceId}`
        : `spotify:album:${spotifyResourceId}`,
    campaignId: campaign.id,
    contentType: campaign.contentType,
  });

  const {
    onClick: onClickAppleMusicPlay,
    isDisabled: isAppleMusicDisabled,
    isLoading: isAppleMusicLoading,
  } = usePlayAppleMusic({
    resourceId: appleMusicResourceId,
    resourceType: campaign.contentType,
    campaignId: campaign.id,
  });

  const onCampaignPlay = useStableCallback(async (source: 'spotify' | 'apple') => {
    const input: MutationReleaseCampaignReportPlayStreamInput = {
      releaseCampaignId: campaign.id,
    };
    setSource(source);

    if (source === 'spotify') {
      if (!loggedInUser?.spotifyAuthConnection?.spotifyUserId && !spotifyAuth.authCode) {
        openToast({
          text: 'Spotify auth code is missing',
          variant: 'error',
        });
        return;
      }

      input.spotifyAuthCode = spotifyAuth.authCode;
      onClickSpotifyPlay();
    } else if (source === 'apple') {
      if (loggedInUser?.appleMusicAuthConnections.length == 0 && !appleMusicAuth?.userToken) {
        openToast({
          text: 'Apple music user token is missing',
          variant: 'error',
        });
        return;
      }
      input.appleMusicAuthUserToken = appleMusicAuth.userToken;
      onClickAppleMusicPlay();
    }

    try {
      const { data } = await reportPlayStream({ input });
      if (
        data.reportPlayStreamReleaseCampaign?.__typename ===
          'MutationReportPlayStreamReleaseCampaignSuccess' ||
        data.reportPlayStreamReleaseCampaign === null
      ) {
        setReceiptData(
          data.reportPlayStreamReleaseCampaign?.data as MembershipReceiptFragment | null,
        );
        setSearchParams({ step: 'claim' });
      }

      if (data.reportPlayStreamReleaseCampaign?.__typename === 'NotAuthorizedError') {
        openToast({
          text: data.reportPlayStreamReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign Report Play Stream - Unauthorized', {
          level: 'error',
        });
      }

      if (data.reportPlayStreamReleaseCampaign?.__typename === 'NotFoundError') {
        openToast({
          text: data.reportPlayStreamReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign Report Play Stream - Not Found', {
          level: 'error',
        });
      }
    } catch (error) {
      captureException(error, {
        tags: { feature: 'Report Play Release Campaign', campaignId: campaign.id },
      });
      openToast({
        text: 'There was an error reporting play stream',
        variant: 'error',
      });
    }
  });

  return (
    <View className="z-above1 flex h-full flex-col items-center justify-center gap-6 md2:w-2/3 md2:min-w-[420px] md2:max-w-[420px]">
      <View className="relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden">
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Track/Album Cover"
            className="aspect-square w-[140px] rounded-xl object-cover md2:w-[240px]"
          />
        ) : (
          <UserPlaceholderImage
            id={randomUUID}
            className="aspect-square w-[140px] rounded-xl md2:w-[240px]"
            svgClassName="rounded-xl"
          />
        )}
      </View>

      <View className="z-above1 flex w-full flex-col items-center">
        <Text className="box-border line-clamp-2 w-full px-6 text-center text-[28px]/[34px] font-semibold text-white md2:px-0 md2:text-[40px]/[46px]">
          {title}
        </Text>

        <Link
          className="mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline"
          to={artistNavigationPath(artistLinkValue, '/')}
        >
          <ArtistProfileImage
            className="h-6 w-6 md2:h-8 md2:w-8"
            profileImageUrl={artistImageUrl}
            withVaultTheme
          />

          <Text
            className={twMerge(
              'line-clamp-1 text-[14px]/[18px] text-white',
              'md2:text-[16px]/[20px] md2:font-medium',
            )}
          >
            {artistName}
          </Text>
        </Link>
      </View>

      <View className="z-above1 flex w-full flex-col items-center gap-4 px-6 md2:px-0">
        {isSpotifyAccountConnected && spotifyResourceId != null && (
          <Button
            label="Play"
            type="primary"
            leadingIcon={faSpotify}
            loading={isLoading || isSpotifyLoading}
            disabled={isSpotifyDisabled}
            onClick={() => onCampaignPlay('spotify')}
            disabledClassName="opacity-40"
          />
        )}

        {isAppleMusicAccountConnected && appleMusicResourceId != null && (
          <Button
            label="Play"
            type="primary"
            leadingIcon={faApple}
            loading={isLoading || isAppleMusicLoading}
            onClick={() => onCampaignPlay('apple')}
            disabled={isAppleMusicDisabled}
            disabledClassName="opacity-40"
          />
        )}
      </View>
    </View>
  );
}

const CLAIM_CAROUSEL_DATA = [
  {
    title: 'You earned a receipt',
    description: 'Receipts go towards earning rewards with Baby Jake',
    component: 'receipt',
  },
  {
    title: 'Your membership card',
    description: "As you collect more receipts, you'll unlock more rewards and access.",
    component: 'membershipCard',
  },
  {
    title: 'Access my Vault',
    description:
      "I'll share music, images, videos, and more as my way of thanking you for your support.",
    component: 'vault',
  },
  {
    title: 'Chat with me',
    description:
      'Hang out in the group chat with me and let me know what you think about my music.',
    component: 'chat',
  },
];

function ClaimView({
  campaign,
  receiptData,
  source,
  artistData,
}: {
  campaign: ClaimReceiptViewFragment;
  receiptData: MembershipReceiptFragment | null;
  source: 'apple' | 'spotify' | null;
  artistData?: {
    membershipImage: string | null | undefined;
    linkValue: string | null | undefined;
    name: string | null | undefined;
  };
}) {
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { isDesktop } = useWindow();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();

  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);
  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);

  const spotifyAuth = useSpotifyAuth({ presaveEnabled: spotifyConnectEnabled });
  const appleMusicAuth = useAppleMusicAuth({ appleMusicEnabled: appleMusicConnectEnabled });

  const { data, isLoading } = useQuery(LastMembershipReceiptDocument, {
    variables: {
      artistHandle,
      releaseCampaignId: campaign.id,
      ...(source === 'spotify' && { spotifyAuthCode: spotifyAuth.authCode }),
      ...(source === 'apple' && { appleMusicUserToken: appleMusicAuth.userToken }),
    },
    enabled: !!source,
    staleTime: 0,
    select(data) {
      return data.data.lastMembershipReceipt;
    },
  });

  // Use receiptData if available, otherwise fall back to data from the query
  const receipt = receiptData || getFragment(MembershipReceiptFragmentDoc, data);

  const randomMemberId = useMemo(() => Math.floor(Math.random() * 10000000), []);

  const randomSerialId = useMemo(() => Math.floor(Math.random() * 1000), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(prev => (prev + 1) % CLAIM_CAROUSEL_DATA.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev - 1 + CLAIM_CAROUSEL_DATA.length) % CLAIM_CAROUSEL_DATA.length);
  };

  const scrollToSlide = (index: number) => {
    const container = containerRef.current;
    if (container) {
      const child = container.firstChild as HTMLElement | null;
      const childWidth = child?.getBoundingClientRect().width || 0;
      container.scrollLeft = childWidth * index;
    }
  };

  const onScroll: DebouncedFunc<UIEventHandler<HTMLDivElement>> = useMemo(
    () =>
      debounce(
        e => {
          const container = e.target as HTMLDivElement;
          const scrollLeft = container.scrollLeft;
          const child = container.firstChild as HTMLElement | null;
          const childWidth = child?.getBoundingClientRect().width || 0;
          const newIndex = Math.round(scrollLeft / childWidth);

          if (newIndex !== currentSlide) {
            setCurrentSlide(newIndex);
          }
        },
        100,
        { trailing: true },
      ),
    [currentSlide],
  );

  const onClaimReceipt = useStableCallback(() => {
    const linkValue = receipt?.membership.artist.linkValue || artistData?.linkValue || artistHandle;
    const queryParams = constructQueryParams({
      linkSpotify: source === 'spotify' ? true : undefined,
      linkAppleMusic: source === 'apple' ? true : undefined,
      artistHandle: linkValue,
      openBottomSheet: 'freeTierModal',
      redirect: null,
    });

    if (loginStatus !== LoginStatus.LOGGED_IN) {
      navigate(`${ROUTES.SIGN_IN}?${queryParams}`);
    } else {
      navigate(artistNavigationPath(linkValue, '/membership', queryParams));
    }
  });

  useEffect(() => {
    scrollToSlide(currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    if (!source) setSearchParams({ step: 'view' });
  }, [setSearchParams, source]);

  const renderCarouselContent = (component: string | undefined) => {
    if (!component) return null;
    switch (component) {
      case 'receipt':
        return (
          <ReceiptUI
            className="h-[378px] w-[280px] md2:h-[425px] md2:w-[300px]"
            title={campaign.title}
            receiptNumber={receipt?.serialNumber || randomSerialId}
            username={receipt?.user.username || receipt?.user.displayName || '@yourname'}
            memberId={receipt?.membership.serialNumber || randomMemberId}
            artistHandle={
              receipt?.membership.artist.linkValue || artistData?.linkValue || artistHandle || ''
            }
            createdAt={receipt?.createdAt || new Date().toISOString()}
            type={campaign.presavesEnabled ? 'pre-save' : 'streaming'}
          />
        );
      case 'membershipCard':
        return (
          <div className="relative box-border flex h-[378px] w-[280px] flex-col items-center px-4 pt-6 md2:h-[425px] md2:w-[300px]">
            <MembershipBadge
              isLoadingData={isLoading}
              artistName={receipt?.membership.artist.name || artistData?.name || artistHandle || ''}
              serialNumber={receipt?.membership.serialNumber || randomMemberId}
              imageUrl={
                receipt?.membership.artist.membershipCardImage?.url || artistData?.membershipImage
              }
              displayName={receipt?.user.username || receipt?.user.displayName || 'yourname'}
              createdAt={receipt?.membership.createdAt || new Date().toDateString()}
              points={receipt?.membership.points || 0}
            />
          </div>
        );
      case 'vault':
        return <CarouselItem icon={faHardDrive} />;
      case 'chat':
        return <CarouselItem icon={faMessages} />;
      default:
        return null;
    }
  };

  return (
    <View className="my-8 flex h-full flex-1 flex-col items-center justify-center">
      {isDesktop ? (
        <View className="z-above4 flex flex-row items-center gap-8">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
            onClick={prevSlide}
          />
          {renderCarouselContent(CLAIM_CAROUSEL_DATA[currentSlide]?.component)}
          <FontAwesomeIcon
            icon={faChevronRight}
            className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
            onClick={nextSlide}
          />
        </View>
      ) : (
        <View
          containerRef={containerRef}
          className="no-scrollbar flex w-full snap-x snap-mandatory overflow-x-auto"
          onScroll={onScroll}
        >
          {CLAIM_CAROUSEL_DATA.map((item, index) => (
            <motion.div
              key={index}
              className="flex w-full flex-none snap-center flex-col items-center justify-center"
            >
              <View className="z-above4 flex flex-col items-center gap-8">
                {renderCarouselContent(item.component)}
              </View>
            </motion.div>
          ))}
        </View>
      )}

      {/* Dots */}
      <View className="z-overlay mt-4 flex justify-center">
        {CLAIM_CAROUSEL_DATA.map((_, index) => (
          <View
            key={index}
            className={`mx-1 h-2 w-2 rounded-full ${
              index === currentSlide ? 'bg-yellow100' : 'bg-base500'
            }`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </View>

      {/* Text content */}
      <View className="mt-2 w-full max-w-md px-4">
        <View className="relative">
          <View className="flex flex-col items-center justify-center overflow-hidden rounded-lg p-4 text-center">
            {CLAIM_CAROUSEL_DATA[currentSlide]?.title && (
              <Text className="mb-2 text-title-xl font-semibold text-white">
                {CLAIM_CAROUSEL_DATA[currentSlide]?.title}
              </Text>
            )}

            {CLAIM_CAROUSEL_DATA[currentSlide]?.description && (
              <Text className="w-full text-base-l text-base400 md2:w-5/6">
                {CLAIM_CAROUSEL_DATA[currentSlide]?.description}
              </Text>
            )}
          </View>
        </View>
      </View>

      <Button
        type="primary"
        label="Claim receipt"
        className="z-overlay mx-8 mt-4 w-[250px]"
        onClick={onClaimReceipt}
      />
    </View>
  );
}

// Placeholder components for other carousel items
const CarouselItem = ({ icon }: { icon: IconDefinition }) => (
  <div className="relative box-border flex h-[378px] w-[280px] flex-col items-center justify-center px-4 md2:h-[425px] md2:w-[300px]">
    <View className="flex h-full w-[260px] items-center justify-center rounded-lg bg-white/5">
      <FontAwesomeIcon icon={icon} className="text-[88px]" />
    </View>
  </div>
);

export const ClaimReceiptViewSkeleton = () => {
  return (
    <View className="box-border h-full w-full items-center justify-center overflow-x-hidden md2:flex">
      <View className="z-above1 flex h-full flex-col items-center justify-center gap-6 md2:w-2/3 md2:min-w-[420px] md2:max-w-[420px]">
        <View className="relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden">
          <LoadingSkeleton className="aspect-square w-[140px] rounded-xl object-cover md2:w-[240px]" />
        </View>

        <View className="z-above1 flex w-full flex-col items-center">
          <LoadingSkeleton className="h-[40px] w-[280px] md2:h-[46px] md2:w-[300px]" />

          <View className="mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline">
            <LoadingSkeleton className="aspect-square h-6 w-6 rounded-full md2:h-8 md2:w-8" />
            <LoadingSkeleton className="h-[20px] w-[100px] md2:h-[22px]" />
          </View>
        </View>

        <View className="z-above1 flex w-full flex-col items-center px-6 md2:px-0">
          <Button
            label="Play"
            type="primary"
            leadingIcon={faSpotify}
            disabledClassName="opacity-70"
            disabled
          />
        </View>
      </View>
    </View>
  );
};
