import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { faSearch } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { useQuery } from '../../graphql/client';
import type { ReleaseCampaignContentType } from '../../graphql/generated';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { ThirdPartyReleaseInformationDocument } from '../../graphql/generated';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { isValidAppleMusicLink, isValidSpotifyLink } from '../../utils/linkUtils';
import { extractAppleMusicResourceId, extractSpotifyResourceId } from '../../utils/linkUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { getDSPName, parseContentType, validateField } from './helpers';
import { CampaignSteps, CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

gql(/* GraphQL */ `
  query ThirdPartyReleaseInformation($input: QueryThirdPartyReleaseInformationInput!) {
    thirdPartyReleaseInformation(input: $input) {
      __typename
      ... on QueryThirdPartyReleaseInformationSuccess {
        data {
          releaseDate
          releaseImageUrl
          releaseTitle
          releaseArtistName
          urls {
            platform
            url
          }
        }
      }

      ... on Error {
        message
      }
    }
  }
`);

export const CampaignScanLinkView = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const [linkToScan, setLinkToScan] = useState('');
  const [linkType, setLinkType] = useState<'APPLE_MUSIC' | 'SPOTIFY' | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);
  const [contentType, setContentType] = useState<ReleaseCampaignContentType | null>(null);
  const [shouldQuery, setShouldQuery] = useState(false);

  const { fields, setField } = useCampaignForm();

  useVaultTheme();

  const {
    data: scanLinkQuery,
    isLoading,
    error,
  } = useQuery(ThirdPartyReleaseInformationDocument, {
    variables: !!linkToScan &&
      !!contentType &&
      !!linkType && {
        input: {
          appleMusicResourceId:
            linkType === ThirdPartyPlatform.AppleMusic
              ? extractAppleMusicResourceId(linkToScan)
              : undefined,
          spotifyResourceId:
            linkType === ThirdPartyPlatform.Spotify
              ? extractSpotifyResourceId(linkToScan)
              : undefined,
          resourceType: contentType,
        },
      },
    enabled: !errorMessage && shouldQuery,
    staleTime: 0,
    select(data) {
      return data.data.thirdPartyReleaseInformation;
    },
  });

  useEffect(() => {
    if (isValidSpotifyLink(linkToScan)) {
      setLinkType(ThirdPartyPlatform.Spotify);
      setContentType(
        parseContentType({
          key: ThirdPartyPlatform.Spotify,
          uri: linkToScan,
        }),
      );
    } else if (isValidAppleMusicLink(linkToScan)) {
      setLinkType(ThirdPartyPlatform.AppleMusic);
      setContentType(
        parseContentType({
          key: ThirdPartyPlatform.AppleMusic,
          uri: linkToScan,
        }),
      );
    } else {
      setLinkType(null);
      setContentType(null);
    }
  }, [linkToScan]);

  const handleClick = useStableCallback(() => {
    setErrorMessage(null);
    if (!linkType || !contentType) return;

    setShouldQuery(true);
  });

  useEffect(() => {
    if (!shouldQuery || !contentType || !linkType) return;

    if (scanLinkQuery?.__typename === 'QueryThirdPartyReleaseInformationSuccess') {
      const { releaseDate, releaseImageUrl, releaseTitle, releaseArtistName, urls } =
        scanLinkQuery.data;
      setField('releaseDate', new Date(releaseDate));
      validateField('releaseDate');
      setField('title', releaseTitle);
      validateField('title');
      if (releaseArtistName) setField('artist', releaseArtistName);
      if (releaseImageUrl) setField('thirdPartyImageUrl', releaseImageUrl);

      setField('contentType', contentType);
      const dspArray = urls.map(({ platform, url }) => ({
        key: platform,
        uri: url,
        showLink: true,
        buttonText: fields.campaignType === CampaignType.Presave ? 'Presave' : 'Play',
        name: getDSPName(platform),
      }));
      setSearchParams({ step: CampaignSteps.Setup });
      setField('dsps', dspArray);
      validateField('dsps');

      setShouldQuery(false);
    } else if (scanLinkQuery?.__typename === 'ValidationError') {
      setErrorMessage(scanLinkQuery.message);
      setShouldQuery(false);
    }
  }, [
    scanLinkQuery,
    shouldQuery,
    contentType,
    linkType,
    setField,
    setSearchParams,
    fields.campaignType,
  ]);

  useEffect(() => {
    setErrorMessage(error?.message);
    setShouldQuery(false);
  }, [error]);

  return (
    <View className="mx-5 flex h-full min-h-[90vh] flex-1 flex-col items-center justify-center md2:max-w-[600px]">
      {isLoading ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className="inline-block animate-spin rounded-full font-medium text-vault_text"
          fontSize={48}
        />
      ) : (
        <FontAwesomeIcon className="text-vault_text" icon={faSearch} fontSize={48} />
      )}
      <Text className="mt-8 text-center text-title-l font-medium text-vault_text">
        {isLoading ? 'Scanning...' : 'Scan for your song'}
      </Text>
      <Text className="mt-2 max-w-[200px] text-center text-base-m font-normal text-vault_text/50">
        {isLoading
          ? 'Takes up to 30 seconds'
          : 'Enter a URL for your song on Spotify or Apple Music'}
      </Text>

      <View className="mt-5 flex w-full flex-col gap-2">
        <input
          className={clsx(
            'rounded-md border border-solid bg-transparent p-4 !text-base-l text-vault_text placeholder:text-vault_text/50 focus:font-normal focus:outline-none',
            errorMessage ? 'border-destructive300' : 'border-vault_text/10 focus:border-vault_text',
          )}
          placeholder="https://"
          value={linkToScan}
          onChange={e => {
            setLinkToScan(e.target.value);
          }}
        />
        {errorMessage && <Text className="text-base-s text-destructive300">{errorMessage}</Text>}
      </View>

      {!isLoading && (
        <Button
          type="primary-themed"
          label={errorMessage ? 'Retry' : 'Scan'}
          className="mt-8 w-full"
          disabled={!contentType || !linkType || isLoading}
          disabledClassName="opacity-50 cursor-not-allowed"
          onClick={() => handleClick()}
        />
      )}

      {errorMessage && (
        <Text
          className="mt-4 text-center text-base-m text-vault_text/50"
          onClick={() => setSearchParams({ step: CampaignSteps.Setup })}
        >
          Skip
        </Text>
      )}
    </View>
  );
};
