import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useMySubscribers } from '../../hooks/useMySubscribers';
import { MyMemberRow } from '../../screens/settings/MySubscribersPage';

import { artistNavigationPath } from '../../utils/navigationUtils';
import { EventItemSkeleton } from './EventItem';
import { Item } from './shared';

export function NewMembersSection() {
  const { subscribers: members, isInitialLoading } = useMySubscribers({ limit: 3, sort: 'DESC' });
  const { artistHandle } = useArtistHandle();

  const hasMembers = members.length > 0;

  const firstThreeMembers = useMemo(() => members.slice(0, 3), [members]);

  if (isInitialLoading) {
    return (
      <Item header="New events">
        {Array.from({ length: 3 }).map((_, index) => (
          <EventItemSkeleton key={index} />
        ))}
      </Item>
    );
  }

  if (!hasMembers) {
    return null;
  }

  return (
    <Item
      header="New members"
      headerLink={
        <Link
          className="font-base text-base-m font-medium text-vault_text no-underline opacity-60"
          to={artistNavigationPath(artistHandle, '/members')}
        >
          See all
        </Link>
      }
    >
      {firstThreeMembers.map(
        ({
          id,
          user,
          tier,
          status,
          createdAt,
          phone,
          email,
          joinedViaReferralCode,
          isTrial,
          vaultSubscriptionSourceText,
          vaultSubscriptionSourceType,
          geoLocation,
          artistMembership,
        }) => (
          <MyMemberRow
            key={user.id}
            id={id}
            user={user}
            tier={tier}
            status={status}
            createdAt={createdAt}
            phone={phone}
            email={email}
            joinedViaReferralCode={joinedViaReferralCode}
            isTrial={isTrial}
            geoLocation={geoLocation}
            vaultSubscriptionSourceText={vaultSubscriptionSourceText}
            vaultSubscriptionSourceType={vaultSubscriptionSourceType}
            artistMembership={artistMembership}
            withVaultTheme
          />
        ),
      )}
    </Item>
  );
}
