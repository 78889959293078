import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import type { VirtuosoHandle } from 'react-virtuoso';
import { gql } from '@soundxyz/gql-string';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  FeatureTypename,
  getFragment,
  ReplyToMessageFragmentDoc,
  TierTypename,
  VaultType,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useSendMessage } from '../../hooks/useSendMessage';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import {
  setReplyToMessage,
  useReplyToMessage,
  useSetActiveArtistChatHandle,
  useVaultMessageChannel,
} from '../../hooks/useVaultMessageChannel';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { HorizontalMessageMediaCarousel } from '../chat/HorizontalMessageMediaCarousel';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { MessageChannelLayout, NewMessageLayout } from '../layouts/MessageChannelLayout';
import { GifKeyboard } from '../message/GifKeyboard';
import { MessageTextInput, SkeletonMessageTextInput } from '../message/MessageTextInput';
import { ReplyToMessage } from '../message/ReplyToMessage';
import {
  EmptyMessageChannel,
  MessageChannelView,
  SkeletonMessageChannel,
} from './MessageChannelView';

gql(/* GraphQL */ `
  query GetMessageChannelByArtistHandle(
    $input: QueryMessageChannelByArtistHandleInput!
    $first: Int!
    $after: String
    $asArtistId: UUID
  ) {
    messageChannelByArtistHandle(input: $input) {
      id
      vault {
        id
        type
        artist: artistProfile {
          id
          name
          linkValue
          profileImage {
            id
            url
          }
        }
        activeSubscription {
          id
          ...ActiveSubscriptionFeatures
        }
        price
        contentCount
        tiers {
          __typename
          enabledFeatures {
            feature {
              __typename
            }
          }
        }
      }
      ...messageChannelView
    }
  }
`);

export const VaultMessageChannelView = ({
  artistName,
  contentCount,
  isOwner,
  artistProfileImage,
  activeSubscriptionFeatures,
}: {
  artistName: string | undefined;
  contentCount: number | undefined;
  isOwner: boolean;
  artistProfileImage: string | undefined;
  activeSubscriptionFeatures: TierFeatures | null;
}) => {
  const { loginStatus } = useAuthContext();
  const replyToMessageFrag = useReplyToMessage();
  const { artistHandle } = useArtistHandle();
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const hasChatWriteAccess =
    activeSubscriptionFeatures?.enabledFeatures.ChatWrite === true || isOwner;

  useSetActiveArtistChatHandle();

  const replyToMessage = getFragment(ReplyToMessageFragmentDoc, replyToMessageFrag);
  const [gifKeyboardOpen, setGifKeyboardOpen] = useState(false);

  const {
    query: { firstPage, orderedList: messages, hasNextPage, loadMoreNextPage },
    onMessageChannelUpdate,
    pinnedMessages,
    messageChannel: messageChannelLayoutInfo,
    isInitialLoading,
    clearCustomPages,
  } = useVaultMessageChannel();

  useEffect(() => {
    return () => {
      clearCustomPages();
    };
  }, [clearCustomPages]);

  const navigate = useNavigate();

  const messageChannel = firstPage?.data.messageChannelByArtistHandle;

  const { sendMessage } = useSendMessage({
    artistId: messageChannel?.vault?.artist?.id,
    messageChannelId: messageChannel?.id,
    activeSubscriptionTier: activeSubscriptionFeatures?.tier || null,
  });

  const noArtist = messageChannel?.vault?.artist == null || messageChannelLayoutInfo == null;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const chatAvailableForFreeUsers = messageChannel?.vault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  useEffect(() => {
    if (isInitialLoading || loginStatus === 'LOADING') return;
    if (noArtist) {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  }, [noArtist, artistHandle, navigate, isInitialLoading, loginStatus]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    isInitialLoading ||
    loginStatus === LoginStatus.LOADING ||
    noArtist ||
    messageChannel?.vault?.artist == null ||
    messageChannelLayoutInfo == null
  ) {
    return (
      <LoadingChat
        vaultArtistName={artistName}
        vaultArtistImage={artistProfileImage}
        activeSubscriptionFeatures={activeSubscriptionFeatures}
        messageChannelId={messageChannel?.id}
        hasChatReadAccess={hasChatReadAccess}
        chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
      />
    );
  }

  return (
    <NewMessageLayout
      artistName={artistName}
      contentCount={contentCount}
      artistProfileImage={artistProfileImage}
      activeSubscriptionFeatures={activeSubscriptionFeatures}
      vaultId={messageChannel.vault?.id}
      messageChannelId={messageChannelLayoutInfo.id}
      chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
      secondaryFooter={
        <>
          {replyToMessage && <ReplyToMessage message={replyToMessage} />}
          <HorizontalMessageMediaCarousel artistName={messageChannel.vault.artist.name} />
          {gifKeyboardOpen ? (
            <GifKeyboard
              onSelect={gif => {
                const gifAttachment = {
                  id: gif.id,
                  url: gif.images.fixed_width.url,
                  title: gif.title,
                  aspectRatio:
                    parseFloat(gif.images.fixed_width.width) /
                    parseFloat(gif.images.fixed_width.height),
                };
                sendMessage({
                  content: '',
                  gifAttachment: gifAttachment,
                  replyToMessage: replyToMessage,
                  vaultContent: null,
                  activeSubscriptionTier: activeSubscriptionFeatures?.tier || null,
                });
                virtuosoRef.current?.scrollToIndex({
                  index: 0,
                  behavior: 'smooth',
                });
                setReplyToMessage(null);
                setGifKeyboardOpen(false);
              }}
              closeKeyboard={() => setGifKeyboardOpen(false)}
            />
          ) : (
            <>
              {hasChatWriteAccess ? (
                <MessageTextInput
                  messageChannel={messageChannel}
                  activeSubscriptionTier={activeSubscriptionFeatures?.tier || null}
                  isOwner={isOwner}
                  gifButtonClick={() => setGifKeyboardOpen(true)}
                  vaultId={messageChannel.vault?.id}
                  artist={messageChannel.vault.artist}
                  activeSubscriptionFeatures={activeSubscriptionFeatures}
                  virtuosoRef={virtuosoRef}
                />
              ) : (
                <View className="flex flex-col items-center justify-center gap-4 border-0 border-t-[1px] border-solid border-vault_text/5 p-4">
                  <Text className="!text-base-l text-vault_text/50">
                    {messageChannel.vault.type === VaultType.FreeOnly
                      ? 'Chat writes are locked'
                      : 'Upgrade to All Access to write a message'}
                  </Text>
                  {messageChannel.vault.type !== VaultType.FreeOnly && (
                    <View>
                      <SubscribeButton
                        label="Upgrade"
                        linkValue={messageChannel.vault.artist.linkValue}
                        artistAvatarUrl={messageChannel.vault.artist.profileImage?.url}
                        price={messageChannel.vault.price || DEFAULT_PRICE}
                        vaultId={messageChannel.vault?.id}
                        showBottomSheet={false}
                        component="locked_chat_write_access"
                        className="bg-vault_accent text-vault_accent_text"
                      />
                    </View>
                  )}
                </View>
              )}
            </>
          )}
        </>
      }
      nonScrollingChildren={
        messages.length === 0 ? (
          <EmptyMessageChannel
            sendMessage={c =>
              sendMessage({
                content: c,
                vaultContent: null,
                activeSubscriptionTier: activeSubscriptionFeatures?.tier || null,
              })
            }
          />
        ) : null
      }
    >
      {messageChannel != null && messages.length > 0 && (
        <MessageChannelView
          vaultId={messageChannel.vault.id}
          messageChannel={messageChannel}
          loadMoreNextPage={loadMoreNextPage}
          hasNextPage={hasNextPage}
          onMessageChannelUpdate={onMessageChannelUpdate}
          messages={messages}
          isOwner={isOwner}
          pinnedMessages={pinnedMessages}
          artistLinkValue={artistHandle.toLowerCase()}
          virtuosoRef={virtuosoRef}
          activeSubscriptionFeatures={activeSubscriptionFeatures}
        />
      )}
    </NewMessageLayout>
  );
};

export function LoadingChat({
  vaultArtistName,
  vaultArtistImage,
  activeSubscriptionFeatures,
  messageChannelId,
  hasChatReadAccess,
  chatAvailableForFreeUsers,
}: {
  vaultArtistName: string | undefined;
  vaultArtistImage: string | undefined;
  activeSubscriptionFeatures: TierFeatures | null;
  messageChannelId: string | undefined;
  hasChatReadAccess: boolean;
  chatAvailableForFreeUsers: boolean;
}) {
  return (
    <MessageChannelLayout
      artistName={vaultArtistName}
      secondaryFooter={<SkeletonMessageTextInput />}
      artistProfileImage={vaultArtistImage}
      activeSubscriptionFeatures={activeSubscriptionFeatures}
      messageChannelId={messageChannelId}
      hasChatReadAccess={hasChatReadAccess}
      chatAvailableForFreeUsers={chatAvailableForFreeUsers}
      childrenContainerClassName="overflow-hidden"
    >
      <SkeletonMessageChannel />
    </MessageChannelLayout>
  );
}
