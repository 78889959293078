import { useMemo, useState } from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChartData } from 'chart.js';
import millify from 'millify';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCirclePlus } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMusic } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faReceipt } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { GetWeeklySubscriberDataDocument, ThirdPartyPlatform } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { getTextColor, VaultThemeStore } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { getColorWithOpacity } from '../../utils/colorUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { formatDateString } from '../../utils/textUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { getDSPName, setField } from '../campaign/helpers';
import { CampaignType } from '../campaign/schema';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { UploadButton } from '../layouts/ArtistLayout';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { EventsSection } from './EventsSection';
import { NewMembersSection } from './NewMembersSection';
import { Item } from './shared';

gql(`
  query GetWeeklySubscriberData($vaultId: UUID!) {
    weeklyNewSubscribers(vaultId: $vaultId) {
      newSubscribersPerWeek {
        newSubscriberCount
        weekStart
      }
    }
    mySubscribersSummary(vaultId: $vaultId) {
      activeSubscriptionsCount
    }
  }
`);

export function ArtistMembershipView() {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const navigate = useNavigate();
  const { isDesktop } = useWindow();

  const artist = artistHandle
    ? loggedInUser?.adminArtists?.find(({ artistLinks }) => artistLinks.includes(artistHandle))
    : null;

  if (loginStatus === LoginStatus.LOADING) {
    return (
      <View className="w-full">
        {isDesktop && (
          <View className="mb-5 flex flex-row items-center justify-start gap-2">
            <LoadingSkeleton className="h-12 w-12 rounded-full border-2 border-solid border-vault_background bg-vault_accent bg-opacity-10" />
            <LoadingSkeleton className="h-[22px] w-[100px] bg-vault_accent bg-opacity-10 font-title text-[18px]/[22px] font-medium" />
          </View>
        )}
        <LoadingSkeleton className="mb-8 h-[200px] w-full bg-vault_accent bg-opacity-10" />
        <Item header="Engage with your members">
          <View className="grid w-full grid-cols-2 gap-3">
            <SkeletonArtistActionItem />
            <SkeletonArtistActionItem />
            <SkeletonArtistActionItem />
            <SkeletonArtistActionItem />
          </View>
        </Item>
      </View>
    );
  }

  if (loggedInUser == null || artist == null || artistHandle == null) {
    return (
      <ErrorView
        onRetryClick={() => {
          navigate(artistNavigationPath(artistHandle, '/'));
        }}
        withVaultTheme
        className="mt-20"
      />
    );
  }
  const mainVaultId = artist.artistMainVaultId;

  const { artistName, artistProfileImage } = artist;

  return (
    <View className="mb-10 flex w-full flex-col gap-8">
      {isDesktop && (
        <View className="flex flex-row items-center justify-start gap-2">
          <ArtistProfileImage
            profileImageUrl={artistProfileImage?.url}
            className="h-12 w-12 rounded-full border-2 border-solid border-vault_background"
          />
          <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">
            Hi, {artistName}
          </Text>
        </View>
      )}
      <Chart artistHandle={artistHandle} vaultId={mainVaultId} />
      <Item header="Engage with your members">
        <View className="grid w-full grid-cols-2 gap-3">
          <UploadButton
            folderId={null}
            customLabel="Upload media"
            artistLinkValue={artistHandle}
            vaultId={mainVaultId}
          >
            <ArtistActionItem
              icon={faMusic}
              title="Upload"
              description="Upload your music, photos and videos."
              ctaText="Upload media"
            />
          </UploadButton>
          <ArtistActionItem
            icon={faMobile}
            title="Messages"
            description="Announce events or share general updates."
            ctaText="Send SMS"
            onClick={() => {
              navigate(artistNavigationPath(artistHandle, '/announcements/create'));
            }}
          />
          <ArtistActionItem
            icon={faReceipt}
            title="Pre-save"
            description="Promote your song before its release."
            ctaText="Create event"
            onClick={() => {
              setField('campaignType', CampaignType.Presave);
              setField('dsps', [
                {
                  key: ThirdPartyPlatform.Spotify,
                  name: getDSPName(ThirdPartyPlatform.Spotify),
                  uri: '',
                  buttonText: 'Presave',
                  showLink: true,
                },
                {
                  key: ThirdPartyPlatform.AppleMusic,
                  name: getDSPName(ThirdPartyPlatform.AppleMusic),
                  uri: '',
                  buttonText: 'Presave',
                  showLink: true,
                },
              ]);
              navigate(artistNavigationPath(artistHandle, '/campaign/create'));
            }}
          />
          <ArtistActionItem
            icon={faReceipt}
            title="Streaming"
            description="Promote your song and grow your audience."
            ctaText="Create event"
            onClick={() => {
              setField('campaignType', CampaignType.Stream);
              setField('dsps', [
                {
                  key: ThirdPartyPlatform.Spotify,
                  name: 'Spotify',
                  uri: '',
                  buttonText: 'Play',
                  showLink: true,
                },
                {
                  key: ThirdPartyPlatform.AppleMusic,
                  name: 'Apple Music',
                  uri: '',
                  buttonText: 'Play',
                  showLink: true,
                },
              ]);
              navigate(artistNavigationPath(artistHandle, '/campaign/create'));
            }}
          />
        </View>
      </Item>
      <EventsSection artistHandle={artistHandle} />
      <NewMembersSection />
    </View>
  );
}

function ArtistActionItem({
  icon,
  title,
  description,
  ctaText,
  onClick,
}: {
  icon: IconDefinition;
  title: string;
  description: string;
  ctaText: string;
  onClick?: () => void;
}) {
  return (
    <View
      className="box-border flex h-[140px] w-full flex-col gap-2 rounded-md bg-vault_text bg-opacity-10 p-3 transition-all duration-200 ease-in hover:cursor-pointer hover:opacity-80"
      onClick={onClick}
    >
      <View className="flex w-full flex-row items-center gap-2">
        <FontAwesomeIcon icon={icon} className="text-vault_text" />
        <Text className="font-title text-[15px] font-medium text-vault_text">{title}</Text>
      </View>
      <Text className="flex-1 font-base text-[14px] font-light text-vault_text opacity-50">
        {description}
      </Text>
      <View className="flex w-full flex-row items-center gap-2 font-title text-[14px] font-normal text-vault_accent">
        <FontAwesomeIcon icon={faCirclePlus} />
        <Text>{ctaText}</Text>
      </View>
    </View>
  );
}

function SkeletonArtistActionItem() {
  return (
    <LoadingSkeleton className="box-border flex h-[140px] w-full flex-col gap-2 rounded-md bg-vault_accent bg-opacity-10 p-3" />
  );
}

function Chart({ artistHandle, vaultId }: { artistHandle: string; vaultId: string }) {
  const [bgColors, setBgColors] = useState<(string | CanvasGradient)[]>([]);
  const [activeBar, setActiveBar] = useState<number | null>(null);

  const { accentColor } = useSnapshot(VaultThemeStore);
  const accentTextColor = getTextColor(accentColor);

  const { isDesktop, width } = useWindow();

  const {
    data: { data = [], activeSubscriptionsCount = 0 } = {},
    isLoading,
    isError,
  } = useQuery(GetWeeklySubscriberDataDocument, {
    variables: !!vaultId && { vaultId },
    staleTime: 0,
    select: data => {
      return {
        data: data.data.weeklyNewSubscribers.newSubscribersPerWeek.toReversed(),
        activeSubscriptionsCount: data.data.mySubscribersSummary.activeSubscriptionsCount,
      };
    },
  });

  const activeIndex = activeBar ?? data.length - 1;

  const aspectRatio = useMemo(() => {
    const xPadding = 64;
    const leftMenuWidth = 272;
    const minimumDesktopWidth = 870;

    if (isDesktop) {
      return (minimumDesktopWidth - leftMenuWidth - xPadding) / 111;
    }
    return ((width ?? window.innerWidth) - xPadding) / 111;
  }, [isDesktop, width]);

  const labels = useMemo(() => {
    return data.map(item =>
      formatDateString({ date: item.weekStart, format: 'numerical_month_day' }),
    );
  }, [data]);

  const memberData = useMemo(() => {
    return data.map(item => item.newSubscriberCount);
  }, [data]);

  const maxWeek = useMemo(() => {
    return memberData.reduce((acc, curr) => (curr > acc ? curr : acc), 0);
  }, [memberData]);

  const backgroundColor = useMemo(() => {
    return bgColors.map((gradient, i) => {
      if (i === activeIndex) {
        return accentTextColor;
      }

      return gradient;
    });
  }, [bgColors, activeIndex, accentTextColor]);

  const hoverBackgroundColor = useMemo(() => {
    return Array.from({ length: memberData.length }, (_, i) =>
      i === activeIndex ? accentTextColor : getColorWithOpacity(accentTextColor, 0.3),
    );
  }, [accentTextColor, activeIndex, memberData.length]);

  const barData: ChartData<'bar', number[], string> = {
    labels,
    datasets: [
      {
        data: memberData,
        backgroundColor,
        hoverBackgroundColor,
        borderRadius: { topLeft: 10, topRight: 10 },
      },
    ],
  };

  if (isLoading || isError || data == null) {
    return <LoadingSkeleton className="h-[219px] w-full bg-vault_accent bg-opacity-10" />;
  }

  return (
    <View
      className="box-border flex w-full flex-col justify-between gap-3 rounded-md bg-vault_accent px-4 py-2 hover:cursor-pointer"
      onClick={() => {
        setActiveBar(null);
      }}
    >
      <View className="flex flex-row items-end justify-between">
        <Text className="justify-start font-title text-[8px]/[10px] font-medium text-vault_accent_text ">
          <span className="text-[64px]/[64px]">
            {millify(activeSubscriptionsCount, { lowercase: true })}
          </span>
          <br />
          <span className="text-[12px]/[16px]  font-normal opacity-60">Total members</span>
        </Text>
        <Text className="justify-start text-right font-title text-[8px]/[10px] font-medium text-vault_accent_text">
          <span className="text-[32px]/[32px]">
            {millify(memberData[activeIndex] ?? 0, { lowercase: true })}
          </span>
          <br />
          <Link
            className="text-[12px]/[16px] font-normal text-vault_accent_text no-underline opacity-60"
            to={artistNavigationPath(artistHandle, '/members')}
          >
            {activeIndex < 9
              ? `${formatDateString({
                  date: data[activeIndex]?.weekStart ?? '',
                  format: 'month_day',
                })} - ${formatDateString({
                  date: data[activeIndex + 1]?.weekStart ?? '',
                  format: 'month_day',
                })}`
              : 'Current week'}{' '}
            <FontAwesomeIcon icon={faArrowUpRight} />
          </Link>
        </Text>
      </View>
      <View
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Bar
          ref={chartRef => {
            if (chartRef == null) {
              return;
            }

            const ctx = chartRef.ctx;

            setBgColors(
              memberData.map(data => {
                const gradient = ctx.createLinearGradient(0, 0, 0, 111);

                gradient.addColorStop(
                  Math.min(1 - data / maxWeek, 0.5),
                  getColorWithOpacity(accentTextColor, 0.3),
                );

                gradient.addColorStop(1, getColorWithOpacity(accentTextColor, 0.06));

                return gradient;
              }),
            );
          }}
          data={barData}
          options={{
            onClick(_event, elements) {
              const activeElement = elements[0];

              setActiveBar(activeElement?.index ?? null);
            },
            aspectRatio,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              y: {
                display: false,
              },
              x: {
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
                ticks: {
                  color: getColorWithOpacity(accentTextColor, 0.6),
                },
              },
            },
          }}
        />
      </View>
    </View>
  );
}
