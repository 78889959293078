import { forwardRef, type MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { format } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faChevronDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCalendar } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import {
  formatSelectedDate,
  getAudienceText,
  getZonedDate,
} from '../../../components/announcement/helpers';
import { ANNOUNCEMENT_MAX_CHARS } from '../../../components/announcement/schema';
import { useAnnouncementForm } from '../../../components/announcement/useAnnouncementForm';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useQuery } from '../../../graphql/client';
import {
  ArtistByHandleDocument,
  ScheduledEventAccessFeatureInput,
} from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

export const DateInput = forwardRef<
  HTMLButtonElement,
  {
    className?: string;
    value?: string | null;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    isThemeEnabled: boolean;
  }
>(({ className, value, onClick, isThemeEnabled }, ref) => (
  <button
    className={twMerge(
      'flex w-full cursor-pointer appearance-none items-center justify-between border-none bg-transparent py-5 outline-none',
      isThemeEnabled
        ? 'text-vault_text/50 hover:text-vault_text/60'
        : 'text-base500 hover:text-base400',
      className,
    )}
    ref={ref}
    onClick={onClick}
  >
    <Text className={twMerge('font-base !text-base-l font-normal')}>{value ? value : 'Now'}</Text>
    <FontAwesomeIcon icon={faCalendar} fontSize={16} />
  </button>
));

export function CreateAnnouncementPage() {
  const navigate = useNavigate();
  const currDate = useMemo(() => new Date(), []);
  const { artistHandle } = useArtistHandle();
  const { pathname } = useLocation();
  const { openBottomsheet } = useBottomsheetContainer();

  useVaultTheme();

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.EXIT_FLOW,
        event: EVENTS.CREATE_ANNOUNCEMENT,
      },
      pathname,
    });

    openBottomsheet({
      type: 'EXIT_FLOW',
      exitFlowBottomsheetProps: {
        onConfirm: () => {
          navigate(artistNavigationPath(artistHandle, '/'));
          clearFields();
        },
        event: EVENTS.UPLOAD_TRACK,
      },
    });
  });

  useEffect(() => {
    if (!artistHandle) {
      navigate(ROUTES.NOT_FOUND);
      return;
    }
  }, [artistHandle, navigate]);

  const [charCounter, setCharCounter] = useState(0);

  const getMinTime = useStableCallback(() => {
    const isToday = fields.date.toDateString() === currDate.toDateString();
    return isToday ? currDate : new Date(0, 0, 0, 0, 0, 0, 0);
  });

  const getMaxTime = useStableCallback(() => {
    const maxTime = new Date();
    maxTime.setHours(23, 59, 59, 999);
    return maxTime;
  });

  const { errors, fields, setField, validateField, enableSubmit, clearFields } =
    useAnnouncementForm();

  const timeZone = useMemo(() => {
    return format(getZonedDate(currDate), 'z');
  }, [currDate]);

  const formatDate = formatSelectedDate(fields.date);

  const { loggedInUser } = useAuthContext();

  const { data } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const artistId = data?.data?.artistLink?.artist?.id;

  useEffect(() => {
    if (artistId && loggedInUser?.artist?.id && artistId !== loggedInUser.artist.id) {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  }, [artistHandle, artistId, loggedInUser?.artist?.id, navigate]);

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Announcement</Text>
      }
      headerRight={
        <button
          className="hidden appearance-none border-none bg-transparent outline-none hover:cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 disabled:active:opacity-70 md2:block"
          disabled={!enableSubmit}
          onClick={() => {
            navigate(artistNavigationPath(artistHandle, '/announcements/review'));
          }}
        >
          <Text className="font-title !text-base-m font-medium text-vault_accent active:opacity-70">
            Review
          </Text>
        </button>
      }
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      contentClassName="md2:bg-vault_text/3"
      stretch
    >
      <View className="flex h-full w-full flex-col justify-between pb-6 md2:max-w-[600px]">
        <View className="box-border flex w-full flex-col gap-6 pt-6 md2:gap-7">
          <View className="flex h-auto w-full flex-col gap-3">
            <textarea
              value={fields.announcement}
              name="announcement"
              aria-multiline="true"
              placeholder="Write your announcement here..."
              className={twMerge(
                'box-border min-h-[276px] w-full resize-none rounded-md border bg-transparent p-3 font-base !text-base-l outline-none',
                'border-vault_text/5 text-vault_text placeholder:text-vault_text/50',
              )}
              onChange={e => {
                setField('announcement', e.target.value);
                setCharCounter(e.target.value.length);
                validateField('announcement');
              }}
              onBlur={() => {
                validateField('announcement');
              }}
            />

            <Text
              className={twMerge(
                'h-6 font-base !text-base-s tabular-nums',
                !!errors.announcement ? 'text-destructive400' : 'text-vault_text/50',
              )}
            >
              {charCounter}/{ANNOUNCEMENT_MAX_CHARS}
            </Text>
          </View>

          {loggedInUser?.artist?.mainVaultType === 'FREEMIUM' && (
            <View className="flex w-full flex-col">
              <Text className="font-title !text-title-s font-medium text-vault_text">Audience</Text>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger className="group appearance-none border-none bg-transparent outline-none">
                  <>
                    <View className="flex w-full cursor-pointer items-center justify-between py-5 outline-none">
                      <Text
                        className={twMerge(
                          'font-base !text-base-l font-normal',
                          'text-vault_text/50 group-hover:text-vault_text/60',
                        )}
                      >
                        {!!fields.accessType
                          ? getAudienceText(fields.accessType)
                          : 'Select your audience'}
                      </Text>

                      <FontAwesomeIcon
                        icon={faChevronDown}
                        fontSize={16}
                        className="text-vault_text/50 group-hover:text-vault_text/60"
                      />
                    </View>

                    <hr className="h-[1px] border-none bg-vault_text/5 outline-none" />
                  </>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.Content
                    align="start"
                    className="z-stickyHeader -ml-0.5 flex w-full min-w-[calc(100vw-88px)] flex-col gap-1 rounded-md bg-vault_background p-1 md2:min-w-[510px]"
                  >
                    {Object.values(ScheduledEventAccessFeatureInput).map(accessType => (
                      <DropdownMenu.Item
                        key={accessType}
                        className={twMerge(
                          'w-full cursor-pointer rounded-md border-none px-5 py-2 outline-none',
                          'box-border bg-vault_text/10 text-vault_text hover:bg-vault_text/20',
                        )}
                        onSelect={() => {
                          setField('accessType', accessType);
                        }}
                      >
                        {getAudienceText(accessType)}
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </View>
          )}
          <View className="flex flex-col">
            <Text className="font-title !text-title-s font-medium text-vault_text">
              Date and time
            </Text>
            <DatePicker
              value={formatDate}
              selected={fields.date}
              onChange={date => {
                if (!date) {
                  setField('date', currDate);
                  return;
                }
                setField('date', date);
              }}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              className="w-full border-none bg-transparent py-5 font-base !text-base-l text-vault_text outline-none"
              placeholderText="Now"
              customInput={<DateInput isThemeEnabled />}
              minDate={currDate}
              timeIntervals={1}
              minTime={getMinTime()}
              maxTime={getMaxTime()} // Set max time to the end of the day
            />

            <hr className="h-[1px] border-none bg-vault_text/5 outline-none" />
            <Text className="h-6 pt-1 font-base !text-base-s tabular-nums text-vault_text/50">
              {timeZone}
            </Text>
          </View>
        </View>

        <Button
          type="primary-themed"
          label="Review SMS"
          className="w-fit self-center md2:hidden"
          disabled={!enableSubmit}
          onClick={() => {
            navigate(artistNavigationPath(artistHandle, '/announcements/review'));
          }}
        />
      </View>
    </DefaultLayout>
  );
}
