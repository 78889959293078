import React, { type FC, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { VaultContentType } from '../../graphql/generated';
import { VaultContentAccessFeatureInput } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Toggle } from '../forms/Toggle';

type Props = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  caption: string;
  setCaption: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
  featureAccess: VaultContentAccessFeatureInput;
  setFeatureAccess: React.Dispatch<React.SetStateAction<VaultContentAccessFeatureInput>>;
  isDownloadEnabled: boolean;
  setIsDownloadEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditTrackView: FC<Props> = ({
  title,
  setTitle,
  caption,
  setCaption,
  isDisabled,
  featureAccess,
  setFeatureAccess,
  isDownloadEnabled,
  setIsDownloadEnabled,
}: Props) => {
  const handleFreeMemberAccessChange = (accessType: VaultContentAccessFeatureInput) => {
    setFeatureAccess(accessType);
  };

  return (
    <View className="w-full">
      <View className="mx-4 my-3 flex flex-col gap-6">
        <View className="flex flex-col gap-2">
          <Text className="!text-base-l font-semibold text-vault_text">Title</Text>
          <TitleInput title={title} setTitle={setTitle} isDisabled={isDisabled} />
        </View>
        <View className="flex flex-col gap-2">
          <Text className="!text-base-l font-semibold text-vault_text">Description</Text>
          <CaptionInput caption={caption} setCaption={setCaption} isDisabled={isDisabled} />
        </View>

        <TrackFreeMemberAccessForm
          featureAccess={featureAccess}
          handleFreeMemberAccessChange={handleFreeMemberAccessChange}
          contentType={VaultContentType.Track}
        />

        <EnableDownloadToggleInput
          isEnabled={isDownloadEnabled}
          setIsEnabled={setIsDownloadEnabled}
        />
      </View>
    </View>
  );
};

const TitleInput = ({
  title,
  setTitle,
  isDisabled,
}: {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
}) => {
  return (
    <View className="border-b-1 flex w-full flex-row items-center border-x-0 border-t-0 border-solid border-vault_text/5 py-3">
      <input
        className={twMerge(
          'w-full border-0 bg-transparent !text-base-l focus:font-normal focus:outline-none',
          isDisabled ? 'text-vault_text/50' : 'text-vault_text',
        )}
        placeholder="Add a title..."
        value={title}
        onChange={e => setTitle(e.target.value)}
        disabled={isDisabled}
      />
    </View>
  );
};

const CaptionInput = ({
  caption,
  setCaption,
  isDisabled,
}: {
  caption: string;
  setCaption: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
}) => {
  return (
    <TextareaAutosize
      className={twMerge(
        'mt-2 flex max-h-72 min-h-[70px] w-[inherit] resize-none rounded-md border p-3 font-base !text-base-l focus:font-normal focus:outline-none',
        'border-vault_text/5 bg-transparent text-vault_text placeholder:text-vault_text/50',
      )}
      placeholder="Share a note about your song...."
      value={caption}
      onChange={e => setCaption(e.target.value)}
      disabled={isDisabled}
    />
  );
};

export { EditTrackView };

export const TrackFreeMemberAccessForm = ({
  featureAccess,
  handleFreeMemberAccessChange,
  contentType,
}: {
  featureAccess: VaultContentAccessFeatureInput;
  handleFreeMemberAccessChange: (accessType: VaultContentAccessFeatureInput) => void;
  contentType?: Exclude<VaultContentType, 'FOLDER'>;
}) => {
  const { loggedInUser } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  const vaultType = artistHandle
    ? loggedInUser?.adminArtists?.find(({ artistLinks }) => artistLinks.includes(artistHandle))
        ?.artistMainVaultType
    : null;

  useEffect(() => {
    if (vaultType === 'FREE_ONLY' && contentType !== VaultContentType.Track) {
      handleFreeMemberAccessChange(VaultContentAccessFeatureInput.FreeVaultContent);
    }
  }, [contentType, handleFreeMemberAccessChange, vaultType]);

  if (vaultType === 'FREE_ONLY' && contentType !== VaultContentType.Track) {
    return null;
  }

  return (
    <View
      className={twMerge(
        'border-b-1 flex flex-col border-x-0 border-t-0 border-solid pb-6',
        'border-vault_text/5 placeholder:text-vault_text/50',
      )}
    >
      <Text className="pb-10 !text-title-s font-medium text-vault_text">
        {contentType === VaultContentType.Track
          ? `What should ${vaultType === 'FREE_ONLY' ? '' : 'free '} members have access to?`
          : 'Who should have access?'}
      </Text>

      <View
        className="flex cursor-pointer flex-row justify-between pb-10"
        onClick={() =>
          handleFreeMemberAccessChange(VaultContentAccessFeatureInput.PaidVaultContent)
        }
      >
        <Text className="!text-base-l text-vault_text">
          {contentType === VaultContentType.Track ? 'Snippet only' : 'Paid members only'}
        </Text>
        <input
          type="radio"
          className="custom-radio cursor-pointer"
          checked={featureAccess === VaultContentAccessFeatureInput.PaidVaultContent}
          readOnly
        />
      </View>

      <View
        className="flex cursor-pointer flex-row justify-between pb-2"
        onClick={() =>
          handleFreeMemberAccessChange(VaultContentAccessFeatureInput.FreeVaultContent)
        }
      >
        <Text className="!text-base-l text-vault_text">
          {contentType === VaultContentType.Track ? 'Full song' : 'All members'}
        </Text>
        <input
          type="radio"
          className="custom-radio cursor-pointer"
          checked={featureAccess === VaultContentAccessFeatureInput.FreeVaultContent}
          readOnly
        />
      </View>
    </View>
  );
};

export const EnableDownloadToggleInput = ({
  isEnabled,
  setIsEnabled,
}: {
  isEnabled: boolean;
  setIsEnabled: (enableDownload: boolean) => void;
}) => {
  const { value: trackDownloadsFeatureEnabled } = useGate(FEATURE_GATES.TRACK_DOWNLOADS);
  if (!trackDownloadsFeatureEnabled) {
    return <></>;
  }

  return (
    <View
      className={twMerge(
        'border-b-1 justify-between border-x-0 border-t-0 border-solid pb-6',
        'border-vault_text/5 placeholder:text-vault_text/50',
      )}
    >
      <Toggle
        label="Enable downloads?"
        onChange={e => setIsEnabled(e.target.checked)}
        checked={isEnabled}
        className="bg-acc w-full text-vault_text"
        componentClassName="bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent"
      />
    </View>
  );
};
