import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faArrowUpFromBracket, faMegaphone } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faInbox } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrophy } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFolder } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpArrowDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPalette } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { isUUID4 } from '@soundxyz/utils/validation';
import { loadTrack } from '../../audio/AudioController';
import { Button } from '../../components/buttons/Button';
import { MenuButton } from '../../components/buttons/MenuButton';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { ArtistLayout } from '../../components/layouts/ArtistLayout';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { openFullscreenPlayer } from '../../components/main/AudioPlayer';
import { MediaViewer } from '../../components/message/MediaViewer';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { useListenNowModal } from '../../components/modals/ListenNowModal';
import { VaultContentSkeleton } from '../../components/vault/VaultContents';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { MembershipWelcomeView } from '../../components/views/MembershipView';
import { ReferralLinkView } from '../../components/views/ReferralLinkView';
import {
  lurkState,
  VaultLandingError,
  VaultLandingLoading,
  VaultLandingView,
} from '../../components/views/VaultLandingView';
import { VaultView } from '../../components/views/VaultView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { fetchQuery, useQuery } from '../../graphql/client';
import {
  ArtistByHandleDocument,
  MediaType,
  VisualVaultContentDocument,
} from '../../graphql/generated';
import { useBatchedVaultUnreadAnnouncementCount } from '../../hooks/announcements/useBatchedVaultUnreadAnnouncementCount';
import { usePaginatedVaultAnnouncements } from '../../hooks/announcements/usePaginatedVaultAnnouncements';
import { useMembership, VaultToShowBadge } from '../../hooks/membership/useMembership';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useReferralLinks } from '../../hooks/useReferralLinks';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

gql(/* GraphQL */ `
  query ArtistByHandle($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        name
        linkValue
        mainVaultId
        mainVault {
          id
          messageChannelId
          type
          tiers {
            __typename
            enabledFeatures {
              feature {
                __typename
              }
            }
          }
          activeSubscription {
            id
            status
            ...ActiveSubscriptionFeatures
          }
          isUserArtistAdmin
        }
        profileImage {
          id
          url
          dominantColor
        }
        phoneNumbers {
          phoneNumber
        }
        membershipCardImage {
          id
          url
        }
        ...artistLayout
        ...artistMainVaultView
        ...artistLanding
      }
    }
  }

  query VisualVaultContent($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      id
      title
      linkValue
      ... on VaultImage {
        id
        blurredMediaUrl
        uploadedMedia {
          id
          url
          mediaType
        }
      }
      ... on VaultVideo {
        id
        blurredMediaUrl
        uploadedMedia {
          id
          url
          mediaType
        }
      }
    }
  }
`);

export const VaultPage = () => {
  const { loginStatus } = useAuthContext();

  const { isLurking } = useSnapshot(lurkState);

  useVaultTheme();

  // Show the generic Full Page Loading cause we don't know if the user is logged in or not
  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme />;
  }

  // If the user is logged out and not lurking, show the landing page
  if (loginStatus === LoginStatus.LOGGED_OUT && !isLurking) {
    return <LandingPage />;
  }

  // If the user is logged in or lurking, show the content page
  return <Page />;
};

function Page() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();
  const { loggedInUser, loginStatus } = useAuthContext();
  const { openToast } = useToast();
  const { openListenNowModal } = useListenNowModal();

  const { openVaultCustomization, isVaultCustomizeOpen } = useMenuContainer();

  const vaultToShowBadge = useSnapshot(VaultToShowBadge);
  const code = searchParams.get('code'); // referral link code
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const bottomSheetType = searchParams.get('openBottomSheet');

  const {
    referralCodeData,
    isLoadingReferralCode,
    error: referralCodeError,
    refetch: refetchReferralCode,
  } = useReferralLinks(code);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [showProfileImage, setShowProfileImage] = useState(false);
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { isDesktop } = useWindow();

  const isOwner =
    artistHandle != null &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const { isError, isLoading, data, refetch } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const vaultId = data?.data.artistLink?.artist.mainVaultId;

  const hasActiveSubscription = !!data?.data.artistLink?.artist.mainVault.activeSubscription?.id;
  const showInvitePageIcon = false;

  const announcementNotificationCount = useBatchedVaultUnreadAnnouncementCount({
    vaultId: vaultId,
    enabled: !!vaultId,
  });

  const { membership, isLoading: isLoadingMembership } = useMembership({
    artistHandle,
    hasActiveSubscription,
  });
  const { artist, user, serialNumber, createdAt, points, cardFirstTimeSeen } = membership || {};
  // Overlay is shown when new user joins a vault
  const showBadgeOverlay = useMemo(() => {
    return (
      (vaultId ? !!vaultToShowBadge[vaultId] && cardFirstTimeSeen === false : false) && !isOwner
    );
  }, [cardFirstTimeSeen, isOwner, vaultId, vaultToShowBadge]);

  // Modal is shown to users who are already in the vault
  const showBadgeModal = useMemo(() => {
    return cardFirstTimeSeen === false && !isOwner && hasActiveSubscription;
  }, [cardFirstTimeSeen, hasActiveSubscription, isOwner]);

  const {
    isLoading: isLoadingAnnouncements,
    isError: isErrorAnnouncements,
    orderedList: announcements,
  } = usePaginatedVaultAnnouncements({ enabled: !!artistHandle, artistHandle });

  const showAnnouncementsButton =
    !isLoadingAnnouncements && !isErrorAnnouncements && announcements.length > 0;

  useEffect(() => {
    if (isLoading) return;

    if (trackId != null && isUUID4(trackId)) {
      fetchQuery(VisualVaultContentDocument, { variables: { vaultContentId: trackId } }).then(
        data => {
          if (data.data.vaultContentById == null) return;

          const { __typename, title, linkValue } = data.data.vaultContentById;

          if (__typename === 'VaultTrack' && vaultId) {
            if (hasActiveSubscription || isOwner) {
              if (isDesktop) {
                openListenNowModal({
                  trackId,
                  vaultId,
                });
              } else {
                loadTrack({
                  trackId,
                  vaultId,
                  folderId: null,
                  // without user interaction, autoplay is not allowed
                  autoplay: false,
                  component: 'track_landing_page',
                }).then(() => {
                  openFullscreenPlayer();
                });
              }
            } else {
              navigate(
                artistNavigationPath(artistHandle, linkValue ? `/t/${linkValue}` : `/${trackId}`),
              );
            }
          } else if (__typename === 'VaultFolder') {
            navigate(
              artistNavigationPath(
                artistHandle,
                linkValue ? `/f/${linkValue}` : `/folder/${trackId}`,
              ),
            );
          } else if (__typename === 'VaultImage' || __typename === 'VaultVideo') {
            if (!hasActiveSubscription && !isOwner) {
              const typeName = __typename === 'VaultImage' ? 'i' : 'v';

              return navigate(
                artistNavigationPath(
                  artistHandle,
                  linkValue ? `/${typeName}/${linkValue}` : `/${typeName}/${trackId}`,
                ),
              );
            }
            const { uploadedMedia, blurredMediaUrl } = data.data.vaultContentById;

            if (uploadedMedia != null) {
              const { id, url, mediaType } = uploadedMedia;
              openOverlay(
                <MediaViewer
                  title={title ?? ''}
                  medias={[{ id, url, type: mediaType }]}
                  onClose={closeOverlay}
                />,
              );
            } else if (blurredMediaUrl != null) {
              openOverlay(
                <MediaViewer
                  title={title ?? ''}
                  medias={[{ id: trackId, url: blurredMediaUrl, type: MediaType.Image }]}
                  onClose={closeOverlay}
                />,
              );
            }
          }
        },
      );
    }
  }, [
    artistHandle,
    closeOverlay,
    hasActiveSubscription,
    isDesktop,
    isLoading,
    isOwner,
    navigate,
    openListenNowModal,
    openOverlay,
    trackId,
    vaultId,
  ]);

  useEffect(() => {
    if (code && referralCodeData === null) {
      navigate(window.location.pathname, { replace: true });
      openToast({
        text: 'Referral code does not exist',
        variant: 'error',
      });
    }
  }, [code, referralCodeData, navigate, openToast]);

  const onShareClick = useStableCallback(() => {
    const link = generateShareLink({
      artistLinkValue: data?.data.artistLink?.artist.linkValue,
      inviteCode: loggedInUser?.inviteCode,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: true,
      },
      shareBottomsheetProps: {
        link,
        artistName: data?.data.artistLink?.artist.name ?? 'vault',
        withVaultTheme: true,
      },
    });
  });

  useSetMetaHeaders({
    title: data?.data.artistLink ? `${data.data.artistLink?.artist.name}'s Vault` : null,
    imageUrl: data?.data.artistLink?.artist.profileImage?.url,
  });

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser || !!code) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({
        artistHandle,
        invite,
        smsCampaignResponseShortcode,
        openBottomSheet: bottomSheetType,
      });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [
    artistHandle,
    bottomSheetType,
    code,
    invite,
    loggedInUser,
    loggedInUser?.username,
    loginStatus,
    navigate,
    smsCampaignResponseShortcode,
  ]);

  const { setIsSelecting, isSelecting } = useSelectVaultContent();

  useEffect(() => {
    if (!isOwner) {
      setIsSelecting(false);
    }
  }, [isOwner, setIsSelecting]);

  useEffect(() => {
    if (
      showBadgeModal &&
      !showBadgeOverlay &&
      artistHandle &&
      vaultId &&
      vaultToShowBadge[vaultId] == null // don't show modal if overlay was shown
    ) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_BADGE,
        membershipBadgeBottomsheetProps: {
          vaultId,
          aspectRatio: 0.5,
          isLoading: isLoadingMembership,
          artistHandle,
          artistName: data?.data.artistLink?.artist.name || artistHandle,
          serialNumber,
          imageUrl: artist?.membershipCardImage?.url,
          displayName: user?.displayName || user?.username,
          createdAt,
          points,
        },
        shared: {
          hideCloseBottomsheetButton: true,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          preventSwipeToDismiss: true,
          withVaultTheme: true,
        },
      });
    }
  }, [
    artist?.membershipCardImage?.url,
    artistHandle,
    createdAt,
    data?.data.artistLink?.artist.name,
    isLoadingMembership,
    points,
    serialNumber,
    showBadgeModal,
    showBadgeOverlay,
    user?.displayName,
    user?.username,
    vaultId,
    openListenNowModal,
    openBottomsheet,
    vaultToShowBadge,
  ]);

  const buttons = useMemo(() => {
    const options: ActionBottomsheetProps['buttons'] = [];

    if (!data?.data.artistLink?.artist.id) return [];

    if (loggedInUser) {
      options.push({
        label: 'Notifications',
        leadingIcon: faInbox,
        type: 'secondary',
        href: `${ROUTES.SETTINGS}/artist-notification/${data.data.artistLink.artist.id}`,
        onClick: () => {
          closeBottomsheet();
        },
      });
    }

    if (isOwner) {
      options.push({
        label: 'Select files',
        leadingIcon: faFolder,
        type: 'secondary',
        onClick: () => {
          setIsSelecting(true);
          closeBottomsheet();
        },
      });

      options.push({
        label: 'Sort files',
        leadingIcon: faArrowUpArrowDown,
        type: 'secondary',
        href: artistNavigationPath(artistHandle, '/rearrange'),
        onClick: () => {
          closeBottomsheet();
        },
      });

      if (vaultId) {
        options.push({
          label: 'Customize vault',
          leadingIcon: faPalette,
          type: 'secondary',
          onClick: () => {
            closeBottomsheet();
            setTimeout(() => openVaultCustomization({ vaultId }), 300);
          },
        });
      }
    }

    options.push({
      label: 'Share',
      leadingIcon: faArrowUpFromBracket,
      type: 'secondary',
      onClick: e => {
        e.stopPropagation();
        onShareClick();
      },
      event: {
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: {
          bottomsheetType: BOTTOMSHEET_TYPES.SHARE,
          entity: 'vault',
          aristId: data.data.artistLink.artist.id,
        },
      },
    });

    return options;
  }, [
    data?.data.artistLink?.artist.id,
    loggedInUser,
    isOwner,
    closeBottomsheet,
    artistHandle,
    vaultId,
    setIsSelecting,
    openVaultCustomization,
    onShareClick,
  ]);

  const onEllipsisClick = useStableCallback(() => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ACTION,
      actionBottomsheetProps: {
        buttons,
        className: 'w-full md2:w-80',
        withVaultTheme: true,
      },
    });
  });

  const onVaultInvitesClick = useStableCallback(() => {
    navigate(artistNavigationPath(artistHandle, '/invites'));
  });

  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);

  const { pathname } = useLocation();

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    membershipV2Enabled &&
    !isOwner &&
    pathname.includes('/vault') &&
    loginStatus !== LoginStatus.LOADING
  ) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} replace />;
  }

  if (membershipV2Enabled && isOwner && !pathname.includes('/vault')) {
    return <Navigate to={artistNavigationPath(artistHandle, '/dashboard')} replace />;
  }

  if (isError || referralCodeError) {
    return (
      <DefaultLayout
        ref={scrollRef}
        showBorder
        showRoundedTop
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={!isDesktop ? <MenuButton className="h-12" withVaultTheme /> : null}
        isHeaderTransparent={!showProfileImage}
        shouldSkipMargin
        withVaultTheme
        headerClassName={
          showProfileImage && !isDesktop
            ? 'border-0 border-solid border-b border-vault_text/5 bg-vault_background'
            : 'bg-transparent'
        }
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={isError ? refetch : refetchReferralCode}
            loggingType="vault_page"
            withVaultTheme
          />
        }
      />
    );
  }

  if (isLoading || data == null || isLoadingReferralCode) {
    return (
      <DefaultLayout
        ref={scrollRef}
        showBorder
        showRoundedTop
        headerLeft={!isDesktop ? <MenuButton className="h-12" withVaultTheme /> : null}
        isHeaderTransparent={!showProfileImage}
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        withVaultTheme
        shouldSkipMargin
        headerClassName={
          showProfileImage && !isDesktop
            ? 'border-0 border-solid border-b border-vault_text/5 bg-vault_background'
            : 'bg-transparent'
        }
      >
        <VaultContentSkeleton />
      </DefaultLayout>
    );
  }

  if (data.data.artistLink == null) {
    // TODO: Handle artist is null
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (code && referralCodeData) {
    return (
      <ReferralLinkView
        code={code}
        referralCodeInfo={referralCodeData}
        artistHandle={artistHandle}
      />
    );
  }

  return (
    <ArtistLayout
      artist={data.data.artistLink.artist}
      showProfileImage={showProfileImage}
      showBadgeOverlay={showBadgeOverlay}
      ref={scrollRef}
      rightHeader={
        !showBadgeOverlay && (
          <View className="relative flex min-h-12 items-center gap-4 md2:min-h-5">
            {isSelecting && isOwner ? (
              <View className="absolute right-0">
                <Button
                  label="Cancel"
                  type="secondary"
                  onClick={() => {
                    setIsSelecting(false);
                  }}
                  className="rounded-full bg-base800 px-4 py-3 text-[14px]/[18px] font-medium text-white"
                />
              </View>
            ) : (
              <>
                {showInvitePageIcon && (
                  <Button
                    iconOnly
                    label=""
                    leadingIcon={faTrophy}
                    disabled={isVaultCustomizeOpen}
                    className="mr-[8px] text-[20px] text-vault_text"
                    onClick={e => {
                      e.stopPropagation();
                      onVaultInvitesClick();
                    }}
                    event={{
                      type: EVENTS.VAULT_INVITES,
                      properties: {
                        artistHandle,
                      },
                    }}
                  />
                )}
                {showAnnouncementsButton && (
                  <Link
                    className={twMerge(
                      'relative mr-[8px] flex flex-row items-center gap-3',
                      isVaultCustomizeOpen && 'pointer-events-none cursor-not-allowed',
                    )}
                    to={
                      isVaultCustomizeOpen
                        ? ''
                        : artistNavigationPath(artistHandle, '/announcements')
                    }
                  >
                    <FontAwesomeIcon
                      icon={faMegaphone}
                      className="text-[20px] text-vault_text"
                      size="lg"
                    />

                    {announcementNotificationCount.unreadCount != null &&
                      announcementNotificationCount.unreadCount > 0 && (
                        <View className="absolute -right-1 top-0 h-2 w-2 items-center justify-center rounded-full bg-vault_text" />
                      )}
                  </Link>
                )}

                <Button
                  iconOnly
                  label=""
                  leadingIcon={faEllipsis}
                  disabled={isVaultCustomizeOpen}
                  className="mr-[8px] text-[20px] text-vault_text"
                  onClick={e => {
                    e.stopPropagation();
                    onEllipsisClick();
                  }}
                  event={{
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: BOTTOMSHEET_TYPES.SHARE,
                      entity: 'vault',
                      aristId: data.data.artistLink.artist.id,
                    },
                  }}
                />
              </>
            )}
          </View>
        )
      }
    >
      {showBadgeOverlay && vaultId ? (
        <MembershipWelcomeView
          vaultId={vaultId}
          childrenCotaninerClassName="my-10"
          title="Welcome to the Vault"
          aspectRatio={0.8}
          isLoading={isLoadingMembership}
          artistHandle={artistHandle}
          artistName={data.data.artistLink.artist.name || artistHandle}
          serialNumber={serialNumber}
          imageUrl={artist?.membershipCardImage?.url}
          displayName={user?.displayName || user?.username}
          createdAt={createdAt}
          points={points}
        />
      ) : (
        <VaultView
          artist={data.data.artistLink.artist}
          setShowProfileImage={setShowProfileImage}
          scrollRef={scrollRef}
          showRightHeader
        />
      )}
    </ArtistLayout>
  );
}

function LandingPage() {
  const { artistHandle } = useArtistHandle();
  const { setShowMenu } = useMenuContainer();

  const { isError, isLoading, data, refetch } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      artistHandle,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [setShowMenu]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError) {
    return <VaultLandingError refetch={refetch} />;
  }

  if (isLoading || data == null) {
    return <VaultLandingLoading />;
  }

  if (data.data.artistLink == null) {
    // TODO: Handle artist is null
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <VaultLandingView artistFrag={data.data.artistLink.artist} />;
}
