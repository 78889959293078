import type { FC, ReactNode } from 'react';
import React from 'react';
import { BackButton } from '../buttons/BackButton';
import { Text } from '../common/Text';
import { DefaultLayout } from './DefaultLayout';

type Props = {
  children?: ReactNode;
  right?: ReactNode;
  nonScrollingChildren?: ReactNode;
  stretch?: boolean;
  withVaultTheme?: boolean;
} & ({ title: string; titleComponent?: null } | { title?: null; titleComponent: ReactNode });

const SettingsLayout: FC<Props> = ({
  children,
  title,
  right,
  nonScrollingChildren,
  titleComponent,
  stretch,
  withVaultTheme = false,
}) => {
  return (
    <DefaultLayout
      withVaultTheme={withVaultTheme}
      stretch={stretch}
      showRoundedTop={false}
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={<BackButton withVaultTheme={withVaultTheme} />}
      headerCenter={
        titleComponent ?? (
          <Text className="font-title text-title-m font-medium text-white">{title}</Text>
        )
      }
      headerRight={right}
      nonScrollingChildren={nonScrollingChildren}
      headerClassName={withVaultTheme ? 'bg-vault_background' : undefined}
    >
      {children}
    </DefaultLayout>
  );
};

export { SettingsLayout };
