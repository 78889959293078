import React from 'react';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';

export const BenefitItem = ({
  icon,
  benefitTitle,
  benefitSubtext,
}: {
  icon: FontAwesomeIconProps['icon'];
  benefitTitle: string;
  benefitSubtext: string;
}) => {
  return (
    <View className="flex h-full gap-4">
      {/* event image */}
      <View className="flex aspect-square w-16 flex-shrink-0 items-center justify-center rounded-md bg-vault_text/10">
        <FontAwesomeIcon icon={icon} className="text-[24px] text-vault_text" />
      </View>
      {/* event meta */}
      <View className="flex flex-col justify-between py-0.5">
        <Text className="font-title text-[18px]/[20px] font-medium text-vault_text">
          {benefitTitle}
        </Text>
        <View className="flex flex-col">
          <Text className="w-[60%] text-[14px]/[16px] font-normal text-vault_text/50">
            {benefitSubtext}
          </Text>
        </View>
      </View>
    </View>
  );
};
