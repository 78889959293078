import type { FC } from 'react';
import React, { useCallback } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { captureException } from '@sentry/react';
import { Navigate, useNavigate } from 'react-router';

import { useGate } from 'statsig-react';
import { faApple, faSpotify } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faArrowRightFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faToolbox } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBank, faCog, faPalette } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faCreditCard, faReceipt, faUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faDollarSquare } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPen } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faInfoCircle } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { mixpanelClient } from '../../clients/mixpanelClient';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { type AuthContextType, useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import {
  ResetTestPhoneUserDocument,
  SpotifyLinkDocument,
  SpotifyUnlinkDocument,
} from '../../graphql/generated';
import { useAppleMusicAuth } from '../../hooks/appleMusic/useAppleMusicAuth';
import { useSpotifyAuth } from '../../hooks/spotify/useSpotifyAuth';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { artistNavigationPath } from '../../utils/navigationUtils';

function AppleMusicConnect() {
  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);

  const appleMusicAuth = useAppleMusicAuth({
    appleMusicEnabled: appleMusicConnectEnabled,
  });

  if (!appleMusicConnectEnabled) return null;

  if (appleMusicAuth.type === 'loading') {
    return (
      <Button
        label="Connect Apple Music"
        type="secondary"
        className="mb-[1px]"
        position="middle"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        disabled
        disabledClassName="opacity-70"
        loading
      />
    );
  }

  if (appleMusicAuth.type === 'connected-without-api-confirmation') {
    return (
      <Button
        label="Reconnect Apple Music"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={appleMusicAuth.connectWithApi}
      />
    );
  }

  if (appleMusicAuth.type === 'apple-music-already-linked') {
    return (
      <Button
        label="Disconnect Apple Music"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={appleMusicAuth.unlink}
      />
    );
  }

  if (appleMusicAuth.type === 'connect') {
    return (
      <Button
        label="Connect Apple Music"
        type="secondary"
        className="mb-[1px]"
        position="middle"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={appleMusicAuth.connect}
      />
    );
  }

  if (appleMusicAuth.type === 'link-apple-music') {
    return (
      <Button
        label="Link Apple Music"
        type="secondary"
        className="mb-[1px]"
        position="middle"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
        onClick={appleMusicAuth.link}
      />
    );
  }

  if (appleMusicAuth.type === 'apple-music-connected-without-user') {
    return (
      <Button
        label="Apple Music Connected"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        disabled
        disabledClassName="opacity-70"
        leadingIcon={faApple}
        leadingIconClassName="w-[20px]"
      />
    );
  }

  passiveExhaustiveGuard(appleMusicAuth);

  return null;
}

function SpotifyConnect({ loggedInUser }: { loggedInUser: AuthContextType['loggedInUser'] }) {
  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);

  const spotifyAuth = useSpotifyAuth({
    presaveEnabled: spotifyConnectEnabled,
  });

  const { mutateAsync: spotifyLink, isLoading: spotifyIsLinking } = useMutation(
    SpotifyLinkDocument,
    {
      retry: 5,
    },
  );

  const { openToast } = useToast();

  const { openBottomsheet } = useBottomsheetContainer();

  const { mutateAsync: unlinkSpotify } = useMutation(SpotifyUnlinkDocument, {
    retry: 5,
  });

  if (!spotifyConnectEnabled) return null;

  if (loggedInUser?.spotifyAuthConnection?.spotifyUserId) {
    return (
      <Button
        label="Disconnect Spotify"
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faSpotify}
        leadingIconClassName="w-[20px]"
        onClick={() => {
          unlinkSpotify({})
            .then(() => {
              openToast({
                text: 'Spotify account disconnected',
                variant: 'success',
              });
            })
            .catch(error => {
              captureException(error, {
                extra: {
                  spotifyAuth,
                },
              });
              openToast({
                text: "Couldn't unlink Spotify account. Please try again later.",
                variant: 'error',
              });
            });
        }}
      />
    );
  }

  return (
    <Button
      label={spotifyAuth.type === 'already-connected' ? 'Link Spotify' : 'Connect Spotify'}
      type="secondary"
      position="middle"
      className="mb-[1px]"
      disabled={spotifyAuth.type === 'loading' || spotifyIsLinking}
      disabledClassName="opacity-70"
      loading={spotifyAuth.type === 'loading' || spotifyIsLinking}
      href={spotifyAuth.link}
      isExternal={!!spotifyAuth.link}
      leadingIcon={faSpotify}
      leadingIconClassName="w-[20px]"
      onClick={() => {
        if (spotifyAuth.type !== 'already-connected') return;

        spotifyLink({
          authCode: spotifyAuth.authCode,
          overrideAccount: false,
        })
          .then(result => {
            if (result.data.linkSpotify.__typename !== 'MutationLinkSpotifySuccess') {
              if (result.data.linkSpotify.__typename === 'SpotifyAlreadyLinkedError') {
                return openBottomsheet({
                  type: 'CONFIRMATION',
                  confirmationBottomsheetProps: {
                    title: 'Spotify already linked',
                    subText:
                      'Your Spotify account is already linked to another Vault account. Would you like to link it to this account instead?',
                    onConfirm() {
                      spotifyLink({
                        authCode: spotifyAuth.authCode,
                        overrideAccount: true,
                      }).then(result => {
                        if (result.data.linkSpotify.__typename === 'MutationLinkSpotifySuccess') {
                          openToast({
                            text: 'Spotify account linked',
                            variant: 'success',
                          });
                        } else {
                          openToast({
                            text: result.data.linkSpotify.message,
                            variant: 'error',
                          });
                        }
                      });
                    },
                  },
                });
              }

              openToast({
                text: result.data.linkSpotify.message,
                variant: 'error',
              });
            } else {
              openToast({
                text: 'Spotify account linked',
                variant: 'success',
              });
            }
          })
          .catch(error => {
            captureException(error, {
              extra: {
                spotifyAuth,
              },
            });
            openToast({
              text: "Couldn't link Spotify account. Please try again later.",
              variant: 'error',
            });
          });
      }}
    />
  );
}

function SettingsArtist({
  loggedInUser,
}: {
  loggedInUser: NonNullable<AuthContextType['loggedInUser']>;
}) {
  const { logout } = usePrivy();
  const { mutateAsync: resetTestUser } = useMutation(ResetTestPhoneUserDocument, {});
  const { closeMenu, setIsAccountOpen, openVaultCustomization } = useMenuContainer();
  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();

  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);

  const artist = loggedInUser.artist;
  const renderLabelComponent = useCallback((label: string) => {
    return <Text className="text-[16px]">{label}</Text>;
  }, []);

  const onLogoutClick = () => {
    closeMenu();
    setIsAccountOpen(false);
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        subText: 'Are you sure you want to logout?',
        onConfirm: async () => {
          trackEvent({ type: EVENTS.SIGN_OUT, properties: null, pathname: location.pathname });
          mixpanelClient.reset();
          if (loggedInUser?.isTestPhoneUser) resetTestUser({}); // reset test phone users
          await logout();
          navigate(ROUTES.VAULTS);
        },
      },
    });
  };

  return (
    <SettingsLayout title="Account">
      <View className="flex w-full flex-col">
        <View className="flex w-full flex-col gap-4">
          <View className="mx-2 flex w-full justify-between">
            <Text className="text-title-m font-medium">{artist?.name}</Text>
            <Text className="mr-2 flex items-center justify-center rounded-full bg-base700 px-2 text-center text-[11px] font-bold text-base500">
              Managed account
            </Text>
          </View>
          <View className="flex w-full flex-col">
            <Button
              label="Edit artist profile"
              labelComponent={renderLabelComponent('Edit artist profile')}
              type="secondary"
              className="mb-[1px]"
              position="top"
              leadingIcon={faPen}
              leadingIconClassName="w-[20px]"
              href="profile-artist"
              event={{
                type: EVENTS.SETTINGS_NAVIGATE,
                properties: { type: 'edit_artist_profile' },
              }}
            />
            {!!artist?.mainVaultId && !!artist.mainVaultId && (
              <Button
                label="Customize vault"
                labelComponent={renderLabelComponent('Customize vault')}
                type="secondary"
                className="mb-[1px]"
                position="middle"
                leadingIcon={faPalette}
                leadingIconClassName="w-[20px]"
                onClick={() => {
                  navigate(
                    artistNavigationPath(
                      artist.mainLinkValue,
                      membershipV2Enabled ? '/vault' : '/',
                    ),
                  );
                  openVaultCustomization({ vaultId: artist.mainVaultId });
                }}
                event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'customize_vault' } }}
              />
            )}
            <Button
              label="Earnings"
              labelComponent={renderLabelComponent('Earnings')}
              type="secondary"
              position="middle"
              className="mb-[1px]"
              leadingIcon={faDollarSquare}
              leadingIconClassName="w-[20px]"
              href="artist-earnings"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'earnings' } }}
            />

            <Button
              label="Payouts"
              labelComponent={renderLabelComponent('Payouts')}
              type="secondary"
              className="mb-[32px]"
              position="bottom"
              leadingIcon={faBank}
              leadingIconClassName="w-[20px]"
              href="payouts"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'payouts' } }}
            />
          </View>
        </View>
        <View className="flex w-full flex-col gap-4">
          <View className="mx-2 flex w-full justify-between">
            <Text className="text-title-m font-medium">My Account</Text>
          </View>
          <View className="flex w-full flex-col">
            <Button
              label="Edit my profile"
              labelComponent={renderLabelComponent('Edit my profile')}
              type="secondary"
              className="mb-[1px]"
              position="top"
              leadingIcon={faPen}
              leadingIconClassName="w-[20px]"
              href="profile"
              event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'edit_profile' } }}
            />
            <SpotifyConnect loggedInUser={loggedInUser} />
            <AppleMusicConnect />
            <Button
              label="Notification settings"
              labelComponent={renderLabelComponent('Notification settings')}
              type="secondary"
              position={loggedInUser.payee != null ? 'middle' : 'bottom'}
              className={loggedInUser.payee != null ? 'mb-[1px]' : 'mb-[32px]'}
              leadingIcon={faCog}
              leadingIconClassName="w-[20px]"
              href="notification-settings"
            />
            {loggedInUser.payee != null && (
              <>
                <Button
                  label="Collaborator Earnings"
                  labelComponent={renderLabelComponent('Collaborator Earnings')}
                  type="secondary"
                  position="middle"
                  className="mb-[1px]"
                  leadingIcon={faDollarSquare}
                  leadingIconClassName="w-[20px]"
                  href="earnings"
                  event={{
                    type: EVENTS.SETTINGS_NAVIGATE,
                    properties: { type: 'collaborator-earnings' },
                  }}
                />
                <Button
                  label="Collaborator Payouts"
                  labelComponent={renderLabelComponent('Collaborator Payouts')}
                  type="secondary"
                  className="mb-[32px]"
                  position="bottom"
                  leadingIcon={faBank}
                  leadingIconClassName="w-[20px]"
                  href="collaborator-payouts"
                  event={{
                    type: EVENTS.SETTINGS_NAVIGATE,
                    properties: { type: 'collaborator-payouts' },
                  }}
                />
              </>
            )}
          </View>
        </View>
        <View className="flex w-full flex-col">
          <Button
            label="Memberships"
            labelComponent={renderLabelComponent('Memberships')}
            type="secondary"
            position="top"
            className="mb-[1px]"
            leadingIcon={faUser}
            leadingIconClassName="w-[20px]"
            href="memberships"
            event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'subscriptions' } }}
          />

          <Button
            label="Billing"
            labelComponent={renderLabelComponent('Billing')}
            type="secondary"
            position="middle"
            className="mb-[1px]"
            leadingIcon={faReceipt}
            leadingIconClassName="w-[20px]"
            href="billing"
            event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'billings' } }}
          />
          <Button
            label="Payment method"
            labelComponent={renderLabelComponent('Payment method')}
            type="secondary"
            position="bottom"
            className="mb-[1px]"
            leadingIcon={faCreditCard}
            leadingIconClassName="w-[20px]"
            href="methods"
            event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'methods' } }}
          />
        </View>
      </View>

      <Button
        label="Account management"
        labelComponent={renderLabelComponent('Account management')}
        type="secondary"
        position="top"
        className="mb-[1px] mt-[30px]"
        leadingIcon={faCog}
        leadingIconClassName="w-[20px]"
        href="account-management"
        event={{
          type: EVENTS.SETTINGS_NAVIGATE,
          properties: { type: 'account-managemen' },
        }}
      />
      <Button
        label="About Vault"
        labelComponent={renderLabelComponent('About Vault')}
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faInfoCircle}
        leadingIconClassName="w-[20px]"
        href="about-vault"
        event={{
          type: EVENTS.SETTINGS_NAVIGATE,
          properties: { type: 'about-vault' },
        }}
      />
      <Button
        label="Sign Out"
        labelComponent={renderLabelComponent('Sign Out')}
        type="secondary"
        position="bottom"
        className="mb-[1px]"
        leadingIcon={faArrowRightFromBracket}
        leadingIconClassName="w-[20px]"
        onClick={onLogoutClick}
        event={{ type: EVENTS.SIGN_OUT, properties: null }}
      />
    </SettingsLayout>
  );
}

const SettingsPage: FC = () => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const { logout } = usePrivy();
  const { mutateAsync: resetTestUser } = useMutation(ResetTestPhoneUserDocument, {});
  const { closeMenu, setIsAccountOpen } = useMenuContainer();
  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();

  const isDevPrev =
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development' ||
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'preview';

  const onLogoutClick = () => {
    closeMenu();
    setIsAccountOpen(false);
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        subText: 'Are you sure you want to logout?',
        onConfirm: async () => {
          trackEvent({ type: EVENTS.SIGN_OUT, properties: null, pathname: location.pathname });
          mixpanelClient.reset();
          if (loggedInUser?.isTestPhoneUser) resetTestUser({}); // reset test phone users
          await logout();
          navigate(ROUTES.VAULTS);
        },
      },
    });
  };

  const renderLabelComponent = useCallback((label: string) => {
    return <Text className="text-[16px]">{label}</Text>;
  }, []);

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }
  if (loggedInUser?.artist) {
    return <SettingsArtist loggedInUser={loggedInUser} />;
  }

  return (
    <SettingsLayout title="Account">
      <Button
        label="Edit my profile"
        labelComponent={renderLabelComponent('Edit my profile')}
        type="secondary"
        className="mb-[1px]"
        position="top"
        leadingIcon={faPen}
        leadingIconClassName="w-[20px]"
        href="profile"
        event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'edit_profile' } }}
      />
      {isDevPrev && loggedInUser?.isTestPhoneUser && (
        <Button
          label="Test User Tools"
          labelComponent={renderLabelComponent('Test User Tools')}
          type="secondary"
          position="middle"
          className="mb-[1px]"
          leadingIcon={faToolbox}
          leadingIconClassName="w-[20px]"
          href="test-user-settings"
        />
      )}
      <SpotifyConnect loggedInUser={loggedInUser} />
      <AppleMusicConnect />
      <Button
        label="Notification settings"
        labelComponent={renderLabelComponent('Notification settings')}
        type="secondary"
        position="bottom"
        className="mb-[32px]"
        leadingIcon={faCog}
        leadingIconClassName="w-[20px]"
        href="notification-settings"
      />

      <Button
        label="Memberships"
        labelComponent={renderLabelComponent('Memberships')}
        type="secondary"
        position="top"
        className="mb-[1px]"
        leadingIcon={faUser}
        leadingIconClassName="w-[20px]"
        href="memberships"
        event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'subscriptions' } }}
      />

      <Button
        label="Billing"
        labelComponent={renderLabelComponent('Billing')}
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faReceipt}
        leadingIconClassName="w-[20px]"
        href="billing"
        event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'billings' } }}
      />

      <Button
        label="Payment method"
        labelComponent={renderLabelComponent('Payment method')}
        type="secondary"
        position={loggedInUser?.payee != null ? 'middle' : 'bottom'}
        className={loggedInUser?.payee != null ? 'mb-[1px]' : 'mb-[32px]'}
        leadingIcon={faCreditCard}
        leadingIconClassName="w-[20px]"
        href="methods"
        event={{ type: EVENTS.SETTINGS_NAVIGATE, properties: { type: 'methods' } }}
      />
      {loggedInUser?.payee != null && (
        <Button
          label="Collaborator Payouts"
          labelComponent={renderLabelComponent('Collaborator Payouts')}
          type="secondary"
          className="mb-[32px]"
          position="bottom"
          leadingIcon={faBank}
          leadingIconClassName="w-[20px]"
          href="collaborator-payouts"
          event={{
            type: EVENTS.SETTINGS_NAVIGATE,
            properties: { type: 'collaborator-payouts' },
          }}
        />
      )}

      <Button
        label="Account management"
        labelComponent={renderLabelComponent('Account management')}
        type="secondary"
        position="top"
        className="mb-[1px]"
        leadingIcon={faCog}
        leadingIconClassName="w-[20px]"
        href="account-management"
        event={{
          type: EVENTS.SETTINGS_NAVIGATE,
          properties: { type: 'account-managemen' },
        }}
      />
      <Button
        label="About Vault"
        labelComponent={renderLabelComponent('About Vault')}
        type="secondary"
        position="middle"
        className="mb-[1px]"
        leadingIcon={faInfoCircle}
        leadingIconClassName="w-[20px]"
        href="about-vault"
        event={{
          type: EVENTS.SETTINGS_NAVIGATE,
          properties: { type: 'about-vault' },
        }}
      />
      <Button
        label="Sign Out"
        labelComponent={renderLabelComponent('Sign Out')}
        type="secondary"
        position="bottom"
        leadingIcon={faArrowRightFromBracket}
        leadingIconClassName="w-[20px]"
        onClick={onLogoutClick}
        event={{ type: EVENTS.SIGN_OUT, properties: null }}
      />
    </SettingsLayout>
  );
};

export { SettingsPage };
