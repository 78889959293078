import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router';
import { FullPageLoading } from '../components/views/FullPageLoading';
import { useAuthContext } from '../contexts/AuthContext';
import { fetchQuery, useMutation } from '../graphql/client';
import { GetSpotifyAuthStateRedirectDocument, SpotifyConnectDocument } from '../graphql/generated';
import {
  newSpotifyConnectState,
  SpotifyConnectState,
  useLinkSpotifyAccount,
} from '../hooks/spotify/useSpotifyAuth';
import { LoginStatus } from '../types/authTypes';

export function SpotifyConnectPage() {
  const navigate = useNavigate();

  const { mutateAsync: connectSpotify } = useMutation(SpotifyConnectDocument, {
    retry: 5,
  });

  const { loginStatus } = useAuthContext();

  const linkSpotify = useLinkSpotifyAccount();

  useEffect(() => {
    if (loginStatus === LoginStatus.LOADING) return;

    SpotifyConnectState.initialValue.then(initialValue => {
      if (!initialValue) return;

      const url = new URL(window.location.href);

      // const storeState = initialValue.state;

      const state = url.searchParams.get('state');
      const code = url.searchParams.get('code');
      const error = url.searchParams.get('error');

      if (!state || !code) {
        navigate('/settings');

        return;
      }

      const handleSpotifyConnect = () => {
        // if (!storeState || state !== storeState) {
        //   navigate('/settings');

        //   return;
        // }

        function finalErrorState() {
          newSpotifyConnectState({
            code: null,
          })
            .catch(error => {
              captureException(error, {});
            })
            .finally(() => {
              // TODO: Spotify connection error modal
            });
        }

        if (error) {
          return finalErrorState();
        }

        const redirectUri = new URL('/spotify-connect', window.location.origin);

        redirectUri.hostname = import.meta.env.VITE_SPOTIFY_CONNECT_HOSTNAME;

        connectSpotify({
          input: {
            redirectUri: redirectUri.href,
            code,
          },
        })
          .then(connection => {
            // eslint-disable-next-line no-console
            console.log(connection.data);

            if (connection.data.connectSpotify.__typename !== 'MutationConnectSpotifySuccess') {
              return finalErrorState();
            }

            return newSpotifyConnectState({
              code,
            })
              .then(() => {
                return linkSpotify({
                  authCode: code,
                });
              })
              .then(() => {
                try {
                  window.close();
                } catch (error) {
                  captureException(error, {
                    level: 'warning',
                  });
                } finally {
                  setTimeout(() => {
                    navigate('/');
                  }, 2000);
                }
              })
              .catch(() => finalErrorState());
          })
          .catch(() => finalErrorState());
      };

      fetchQuery(GetSpotifyAuthStateRedirectDocument, {
        variables: {
          input: {
            state,
          },
        },
        retry: 5,
      })
        .then(({ data }) => {
          if (
            data.spotifyAuthStateRedirectUrl.__typename ===
            'QuerySpotifyAuthStateRedirectUrlSuccess'
          ) {
            const queryRedirectUrl = new URL(data.spotifyAuthStateRedirectUrl.data);
            const redirectUrl = new URL(window.location.href);

            if (redirectUrl.hostname !== queryRedirectUrl.hostname) {
              redirectUrl.hostname = queryRedirectUrl.hostname;

              window.location.href = redirectUrl.href;
            } else {
              handleSpotifyConnect();
            }
          } else {
            handleSpotifyConnect();
          }
        })
        .catch(error => {
          captureException(error, {
            level: 'warning',
          });

          handleSpotifyConnect();
        });
    });
  }, [navigate, connectSpotify, linkSpotify, loginStatus]);

  return <FullPageLoading withVaultTheme={false} />;
}
