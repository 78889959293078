import React, { type LegacyRef, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { useLongPress } from 'use-long-press';
import { faFolder } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpArrowDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../../../constants/flagConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../../../contexts/MenuContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { useToast } from '../../../../contexts/ToastContext';
import { MediaQuery } from '../../../../contexts/mediaQuery';
import { useMutation } from '../../../../graphql/client';
import type { MediaType } from '../../../../graphql/generated';
import {
  type FragmentType,
  getFragment,
  ImageItemFragmentDoc,
  UpsertUserContentViewDocument,
} from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useStableCallback } from '../../../../hooks/useStableCallback';
import { useSelectVaultContent } from '../../../../hooks/vault/useSelectVaultContent';
import { useVaultContentActions } from '../../../../hooks/vault/useVaultContentActions';
import { MoveToContents } from '../../../../screens/vault/MoveToPage';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';

import { MediaViewer } from '../../../message/MediaViewer';
import { ImageItem } from '../ImageItem';
import { type ContentOption, ContentOptions } from './ContentOptions';

export const ArtistImage = ({
  image,
  allAssets,
  containerRef,
  artistHandle,
}: {
  image: FragmentType<ImageItemFragmentDoc>;
  allAssets: Array<{
    id: string;
    type: MediaType;
    url: string;
    title: string;
  }>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const {
    id: imageId,
    linkValue,
    vault,
    title,
    uploadedMedia,
  } = getFragment(ImageItemFragmentDoc, image);

  const { mutate: upsertUserContentView } = useMutation(UpsertUserContentViewDocument, {});
  const { isVaultCustomizeOpen } = useMenuContainer();

  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();

  const { value: sortingEnabled } = useGate(FEATURE_GATES.VAULT_SORTING);

  const { md2 } = MediaQuery.useContainer();
  const [menuHovered, setMenuHovered] = useState(false);

  const [selectedFile, setSelectedFile] = React.useState(false);
  const {
    isSelecting,
    removeContent: removeSelectedContent,
    addContent: addSelectedContent,
  } = useSelectVaultContent();

  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    if (isSelecting || md2) return;
    setIsOpen(true);
  })();

  const link = useMemo(() => {
    const path = linkValue ? `/i/${linkValue}` : `/${imageId}`;
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [linkValue, imageId, artistHandle, loggedInUser?.inviteCode]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Video link copied to clipboard!',
  });

  const { removeContents: deleteFile } = useVaultContentActions();

  const rearrangeButton = {
    title: 'Rearrange',
    icon: faArrowUpArrowDown,
    onClick: () => {
      navigate(artistNavigationPath(artistHandle, '/rearrange'));
    },
  };

  const deleteButton = {
    title: 'Delete',
    icon: faTrash,
    onClick: () => {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.CONFIRMATION,
        confirmationBottomsheetProps: {
          subText: 'Are you sure you want to delete this image?',
          confirmButtonText: 'Delete',
          confirmType: 'delete',
          onConfirm: () => {
            deleteFile({
              contentIds: [imageId],
              vaultId: vault.id,
              onSuccess: () => {
                openToast({
                  text: 'The image has been deleted',
                  variant: 'success',
                });
              },
            });
          },
        },
      });
    },
  } satisfies ContentOption;

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
    {
      title: 'Move to',
      icon: faFolder,
      onClick: () => {
        MoveToContents.contentIds = [imageId];
        navigate(artistNavigationPath(artistHandle, '/move-to'));
      },
    },
    // TODO
    // {
    //   title: 'Edit video',
    //   icon: faMusicNote,
    //   onClick: () => {
    //     navigate(artistNavigationPath(artistHandle, `/edit/${videoId}`));
    //     trackEvent({
    //       type: EVENTS.EDIT_TRACK,
    //       properties: { vaultContentId: videoId, artistHandle },
    //     });
    //   },
    // },
  ];

  if (sortingEnabled) {
    buttons.push(rearrangeButton);
  }

  buttons.push(deleteButton);

  const onSelectFile = useStableCallback(() => {
    return setSelectedFile(prev => {
      if (prev) {
        removeSelectedContent(imageId);
        return false;
      }
      addSelectedContent(imageId);
      return true;
    });
  });

  useEffect(() => {
    if (!isSelecting) {
      setSelectedFile(false);
    }
  }, [isSelecting]);

  const onClick = () => {
    upsertUserContentView({ input: { vaultContentId: imageId } });
    if (uploadedMedia == null) {
      return;
    }

    openOverlay(<MediaViewer title={title ?? ''} medias={allAssets} onClose={closeOverlay} />);
  };

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={isSelecting}
      onLongPress={onLongPress}
      onClick={() => {
        if (isSelecting) {
          return onSelectFile();
        }
        if (!isOpen) {
          return onClick();
        }
      }}
      selectedFile={selectedFile}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={<ImageItem image={image} containerRef={containerRef} hasEllipsis={md2} />}
      disabled={isVaultCustomizeOpen}
      disableHover={isVaultCustomizeOpen}
    />
  );
};
