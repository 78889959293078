import { gql } from '@soundxyz/gql-string';
import { useInfiniteQuery } from '../../graphql/client';
import { SortDirection } from '../../graphql/generated';
import { ArtistCampaignsDocument } from '../../graphql/generated/documents';

gql(/* GraphQL */ `
  query ArtistCampaigns(
    $artistHandle: String!
    $after: String
    $first: Int!
    $sort: SortDirection!
  ) {
    releaseCampaignsByArtistHandle(
      artistHandle: $artistHandle
      first: $first
      sort: $sort
      after: $after
    ) {
      edges {
        node {
          id
          releaseDate
          ...EventItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useArtistReleaseCampaigns = ({
  artistHandle,
  limit = 15,
  sort = SortDirection.Desc,
  source,
}: {
  artistHandle?: string;
  limit?: number;
  sort?: SortDirection;
  source: 'events_page' | 'membership_page';
}) => {
  const {
    orderedList: releaseCamapigns,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  } = useInfiniteQuery(ArtistCampaignsDocument, {
    filterQueryKey: { artistHandle, source },
    staleTime: 0,
    enabled: !!artistHandle,
    getNextPageParam: ({ data }) => {
      return (
        data.releaseCampaignsByArtistHandle.pageInfo.hasNextPage && {
          after: data.releaseCampaignsByArtistHandle.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
          sort,
        };
      }),

    list: ({ releaseCampaignsByArtistHandle }) => {
      return releaseCampaignsByArtistHandle.edges.map(edge => edge.node);
    },
    uniq: ({ id }) => id,
  });

  return {
    releaseCamapigns,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  };
};
