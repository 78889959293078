import { z } from 'zod';
import {
  faAmazon,
  faApple,
  faBandcamp,
  faDeezer,
  faDiscord,
  faSoundcloud,
  faSpotify,
  faWhatsapp,
  faYoutube,
} from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faGlobe, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ReleaseCampaignContentType, ThirdPartyPlatform } from '../../graphql/generated';
import { isValidAppleMusicLink, isValidSpotifyLink } from '../../utils/linkUtils';
import { CAMPAIGN_ERRORS, CampaignFieldSchema, CampaignType } from './schema';
import { campaignState, initialCampaignState } from './store';

const uriSchema = z.string().url();

export const parseContentType = (dsp: {
  key: ThirdPartyPlatform;
  uri: string;
}): 'ALBUM' | 'TRACK' | null => {
  switch (dsp.key) {
    case ThirdPartyPlatform.Spotify:
      return dsp.uri.includes('/album/')
        ? ReleaseCampaignContentType.Album
        : dsp.uri.includes('/track/')
          ? ReleaseCampaignContentType.Track
          : null;
    case ThirdPartyPlatform.AppleMusic:
      const url = new URL(dsp.uri);
      if (url.pathname.includes('/album/')) {
        return url.searchParams.has('i')
          ? ReleaseCampaignContentType.Track
          : ReleaseCampaignContentType.Album;
      } else if (url.pathname.includes('/song/')) {
        return ReleaseCampaignContentType.Track;
      }
      return null;

    default:
      return null;
  }
};

export const getDSPIcon = (key: ThirdPartyPlatform): IconDefinition => {
  const iconMap: Record<ThirdPartyPlatform, IconDefinition> = {
    [ThirdPartyPlatform.Spotify]: faSpotify,
    [ThirdPartyPlatform.AppleMusic]: faApple,
    [ThirdPartyPlatform.Discord]: faDiscord,
    [ThirdPartyPlatform.Deezer]: faDeezer,
    [ThirdPartyPlatform.Whatsapp]: faWhatsapp,
    [ThirdPartyPlatform.Soundcloud]: faSoundcloud,
    [ThirdPartyPlatform.Bandcamp]: faBandcamp,
    [ThirdPartyPlatform.Amazon]: faAmazon,
    [ThirdPartyPlatform.Youtube]: faYoutube,
    [ThirdPartyPlatform.YoutubeMusic]: faYoutube,
    [ThirdPartyPlatform.Other]: faGlobe,
  };

  const icon = iconMap[key] || faGlobe;
  return icon;
};

export const getDSPColor = (key: ThirdPartyPlatform): string => {
  const colorMap: Record<ThirdPartyPlatform, string> = {
    [ThirdPartyPlatform.Spotify]: '#23CF5F',
    [ThirdPartyPlatform.AppleMusic]: '#FC566E',
    [ThirdPartyPlatform.Discord]: '#5462E5',
    [ThirdPartyPlatform.Deezer]: '#A238FF',
    [ThirdPartyPlatform.Whatsapp]: '#6FC666',
    [ThirdPartyPlatform.Soundcloud]: '#FF7700',
    [ThirdPartyPlatform.Bandcamp]: '#629AA9',
    [ThirdPartyPlatform.Amazon]: '#24D1DA',
    [ThirdPartyPlatform.Youtube]: '#FF0000',
    [ThirdPartyPlatform.YoutubeMusic]: '#FF0000',
    [ThirdPartyPlatform.Other]: '#FFFFFF',
  };

  const color = colorMap[key] || '#FFFFFF';
  return color;
};

export const getDSPName = (key: ThirdPartyPlatform): string => {
  const nameMap: Record<ThirdPartyPlatform, string> = {
    [ThirdPartyPlatform.Spotify]: 'Spotify',
    [ThirdPartyPlatform.AppleMusic]: 'Apple Music',
    [ThirdPartyPlatform.Discord]: 'Discord',
    [ThirdPartyPlatform.Deezer]: 'Deezer',
    [ThirdPartyPlatform.Whatsapp]: 'WhatsApp',
    [ThirdPartyPlatform.Soundcloud]: 'SoundCloud',
    [ThirdPartyPlatform.Bandcamp]: 'Bandcamp',
    [ThirdPartyPlatform.Amazon]: 'Amazon Music',
    [ThirdPartyPlatform.Youtube]: 'YouTube',
    [ThirdPartyPlatform.YoutubeMusic]: 'YouTube Music',
    [ThirdPartyPlatform.Other]: 'Other',
  };

  return nameMap[key] || 'Unknown';
};

export const setField = <FieldKey extends keyof typeof campaignState.fields>(
  name: FieldKey,
  value: (typeof campaignState.fields)[FieldKey],
) => {
  if (name === 'title' && value && campaignState.fields.campaignType === CampaignType.Presave) {
    campaignState.fields['message'] = `"${value}" is now available for pre-save!`;
  }
  campaignState.fields[name] = value;
};

export const setError = <FieldKey extends keyof typeof campaignState.errors>(
  name: FieldKey,
  value: (typeof campaignState.errors)[FieldKey],
) => {
  campaignState.errors[name] = value;
};

export const populateFields = (newFields: Partial<typeof campaignState.fields>) => {
  for (const [key, value] of Object.entries(newFields)) {
    if (key in campaignState.fields && value !== undefined) {
      (campaignState.fields as Record<string, unknown>)[key] = value;
    }
  }
};

export const clearErrors = () => {
  campaignState.errors = {
    image: null,
    title: null,
    description: null,
    releaseDate: null,
    dsps: null,
    shouldSendSms: null,
    message: null,
  };
};

export const clearFields = () => {
  campaignState.fields = initialCampaignState().fields;
};

export const validateField = async <FieldKey extends keyof typeof campaignState.errors>(
  name: FieldKey,
) => {
  campaignState.errors[name] = null;

  if (name === 'shouldSendSms' && !campaignState.fields.shouldSendSms) {
    campaignState.errors.message = null;
    return;
  }

  if (name === 'message' && !campaignState.fields.shouldSendSms) {
    return;
  }

  if (name === 'dsps') {
    const dspErrors = await Promise.all(
      campaignState.fields.dsps.map(async dsp => {
        const errors: { key: ThirdPartyPlatform; uri?: string; name?: string } = { key: dsp.key };

        // Validate URI
        if (dsp.uri !== '') {
          const uriResult = await uriSchema.safeParseAsync(dsp.uri);
          if (!uriResult.success) {
            errors.uri = `${dsp.name || dsp.key || 'Provider'} URI must be a valid URL`;
          } else {
            // Additional validation for Spotify and Apple Music
            if (dsp.key === ThirdPartyPlatform.Spotify) {
              if (!isValidSpotifyLink(dsp.uri)) {
                errors.uri = 'Invalid Spotify link format';
              }
            } else if (dsp.key === ThirdPartyPlatform.AppleMusic) {
              if (!isValidAppleMusicLink(dsp.uri)) {
                errors.uri = 'Invalid Apple Music link format';
              }
            }
          }
        }

        // Validate name for custom DSPs
        if (dsp.key === ThirdPartyPlatform.Other && dsp.name.trim() === '') {
          errors.name = 'Name cannot be empty';
        }

        return Object.keys(errors).length > 1 ? errors : null;
      }),
    );
    const filteredErrors = dspErrors.filter(
      (error): error is NonNullable<typeof error> => error !== null,
    );

    campaignState.errors.dsps = filteredErrors.length > 0 ? filteredErrors : null;

    // Content type validation
    const spotifyDsp = campaignState.fields.dsps.find(
      dsp => dsp.key === ThirdPartyPlatform.Spotify && dsp.uri !== '',
    );
    const appleDsp = campaignState.fields.dsps.find(
      dsp => dsp.key === ThirdPartyPlatform.AppleMusic && dsp.uri !== '',
    );

    const spotifyContentType = spotifyDsp ? parseContentType(spotifyDsp) : null;
    const appleContentType = appleDsp ? parseContentType(appleDsp) : null;

    if (spotifyContentType && appleContentType) {
      if (spotifyContentType !== appleContentType) {
        campaignState.errors.contentType = CAMPAIGN_ERRORS.contentType.mismatch;
      } else {
        setField('contentType', spotifyContentType);
        campaignState.errors.contentType = null;
      }
    } else if (spotifyContentType) {
      setField('contentType', spotifyContentType);
      campaignState.errors.contentType = null;
    } else if (appleContentType) {
      setField('contentType', appleContentType);
      campaignState.errors.contentType = null;
    } else {
      campaignState.errors.contentType = null;
    }

    return;
  }

  if (name === 'releaseDate') {
    if (campaignState.fields.campaignType === CampaignType.Presave) {
      const now = new Date();
      if (campaignState.fields.releaseDate <= now) {
        campaignState.errors.releaseDate =
          'Release date must be in the future for presave campaigns';
        return;
      }
    }
  }

  const result = await CampaignFieldSchema.shape[name].safeParseAsync(campaignState.fields[name]);

  if (!result.success) {
    const errorMessage = result.error.errors[0]?.message || `Invalid ${String(name)}`;
    campaignState.errors[name] = errorMessage as (typeof campaignState.errors)[FieldKey];
  }
};

export function setFieldsToUndefined(obj: object) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (typeof value === 'string' && value === '') {
      //@ts-expect-error  - overwrite with undefined
      result[key] = undefined;
    } else {
      //@ts-expect-error - this is fine
      result[key] = value;
    }
    return result;
  }, {});
}
