import { useEffect, useState } from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { isEqual } from 'lodash-es';
import { useNavigate } from 'react-router';
import { subscribe, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation } from '../../graphql/client';
import {
  CreateReleaseCampaignDocument,
  ThirdPartyPlatform,
  UpdateReleaseCampaignDocument,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { extractAppleMusicResourceId, extractSpotifyResourceId } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { uploadMultipartFile } from '../../utils/s3Utils';
import { PersistenceStorage } from '../../utils/storeUtils';
import {
  clearErrors,
  clearFields,
  populateFields,
  setError,
  setField,
  setFieldsToUndefined,
  validateField,
} from './helpers';
import { CampaignFieldSchema, CampaignSteps, CampaignType } from './schema';
import { campaignState, initialCampaignState } from './store';

gql(/* GraphQL */ `
  mutation CreateReleaseCampaign($input: MutationCreateReleaseCampaignInput!) {
    createReleaseCampaign(input: $input) {
      __typename
      ... on MutationCreateReleaseCampaignSuccess {
        data {
          id
        }
      }

      ... on NotFoundError {
        message
      }

      ... on ValidationError {
        message
      }
    }
  }

  mutation UpdateReleaseCampaign($input: MutationUpdateReleaseCampaignInput!) {
    updateReleaseCampaign(input: $input) {
      __typename
      ... on MutationUpdateReleaseCampaignSuccess {
        data {
          id
        }
      }

      ... on NotFoundError {
        message
      }

      ... on ValidationError {
        message
      }

      ... on Error {
        message
      }
    }
  }
`);

const version = '0.1';
const storageKey = `@vault/campaign${ENVIRONMENT === 'production' ? '' : '-' + ENVIRONMENT}-${version}`;

const CampaignPersistence = PersistenceStorage({
  schema: CampaignFieldSchema.partial(),
  key: storageKey,
  eager: true,
});

if (typeof window !== 'undefined') {
  CampaignPersistence.initialValue
    .then(value => {
      if (!value) return;
      Object.assign(campaignState.fields, value);
    })
    .catch(
      // eslint-disable-next-line no-console
      console.error,
    )
    .finally(() => {
      subscribe(
        campaignState.fields,
        () => {
          if (isEqual(campaignState.fields, initialCampaignState().fields)) return;
          const pendingStorageSet = setTimeout(() => {
            const data = setFieldsToUndefined(campaignState.fields);
            const parseToStore = CampaignFieldSchema.partial().safeParse(data);

            if (parseToStore.success) {
              CampaignPersistence.set(parseToStore.data);
            }
            clearTimeout(pendingStorageSet);
          }, 0);
        },
        true,
      );
    });
}

export const useCampaignForm = () => {
  const { fields, errors } = useSnapshot(campaignState, {
    sync: true,
  });
  const { artistHandle } = useArtistHandle();
  const { openBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isLinksComplete, setIsLinksComplete] = useState(false);
  const [isReleaseComplete, setIsReleaseComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: createEvent, isLoading: releaseCampaignCreationLoading } = useMutation(
    CreateReleaseCampaignDocument,
    {},
  );
  const { mutateAsync: updateEvent, isLoading: releaseCampaignUpdateLoading } = useMutation(
    UpdateReleaseCampaignDocument,
    {},
  );

  const hasError = Object.values(errors).some(error => error !== null);

  const enableSubmit =
    !hasError &&
    !isSubmitting &&
    isSetupComplete &&
    isLinksComplete &&
    isReleaseComplete &&
    !releaseCampaignCreationLoading;

  useEffect(() => {
    setIsSetupComplete(
      ((!!fields.image && !!fields.mediaId) || !!fields.thirdPartyImageUrl) &&
        !!fields.title &&
        !!fields.artist &&
        !errors.releaseDate,
    );
  }, [
    fields.image,
    fields.title,
    fields.artist,
    fields.mediaId,
    fields.thirdPartyImageUrl,
    errors.releaseDate,
  ]);

  useEffect(() => {
    const isLinkComplete = () => {
      if (!fields.campaignType || errors.contentType) return false;

      const validDSPs = fields.dsps.filter(dsp => dsp.uri !== '' && dsp.showLink);
      const hasInvalidURIs = fields.dsps.some(
        dsp => dsp.uri !== '' && errors.dsps?.some(e => e?.uri),
      );

      if (fields.campaignType === CampaignType.Presave) {
        const hasSpotifyOrAppleWithShowLink = validDSPs.some(
          dsp =>
            (dsp.key === ThirdPartyPlatform.Spotify || dsp.key === ThirdPartyPlatform.AppleMusic) &&
            dsp.showLink,
        );
        return hasSpotifyOrAppleWithShowLink && !hasInvalidURIs;
      } else if (fields.campaignType === CampaignType.Stream) {
        return validDSPs.length > 0 && !hasInvalidURIs;
      }

      return false;
    };

    setIsLinksComplete(isLinkComplete());
  }, [fields.dsps, fields.campaignType, errors.dsps, errors.contentType]);

  useEffect(() => {
    if (fields.shouldSendSms) {
      setIsReleaseComplete(fields.message?.trim().length > 0 && !!fields.announcementDate);
    } else {
      setIsReleaseComplete(true);
    }
  }, [fields.shouldSendSms, fields.message, fields.announcementDate]);

  const clearAllFields = () => {
    CampaignPersistence.clear();
    clearFields();
  };

  const determineNextStep = (currentStep: CampaignSteps): CampaignSteps | null => {
    if (fields.isEditMode) {
      switch (currentStep) {
        case CampaignSteps.Setup:
          return fields.campaignType === 'presave'
            ? CampaignSteps.PresavePrereleaseLinks
            : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.PresavePrereleaseLinks:
          return CampaignSteps.PresaveReleaseLinks;
        case CampaignSteps.PresaveReleaseLinks:
        case CampaignSteps.StreamReleaseLinks:
          return CampaignSteps.Release;
        case CampaignSteps.Release:
          return CampaignSteps.Preview;
        case CampaignSteps.Preview:
          return null; // This is the last step
        default:
          return currentStep;
      }
    } else {
      // Create flow
      switch (currentStep) {
        case CampaignSteps.Scan:
          return CampaignSteps.Setup;
        case CampaignSteps.Setup:
          return fields.campaignType === 'presave'
            ? CampaignSteps.PresavePrereleaseLinks
            : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.PresavePrereleaseLinks:
          return CampaignSteps.PresaveReleaseLinks;
        case CampaignSteps.PresaveReleaseLinks:
        case CampaignSteps.StreamReleaseLinks:
          return CampaignSteps.Release;
        case CampaignSteps.Release:
          return CampaignSteps.Preview;
        case CampaignSteps.Preview:
          return null; // This is the last step
        default:
          return currentStep;
      }
    }
  };

  const determinePrevStep = (currentStep: CampaignSteps): CampaignSteps | null => {
    if (fields.isEditMode) {
      switch (currentStep) {
        case CampaignSteps.Setup:
          return null; // This is the first step in edit mode
        case CampaignSteps.PresavePrereleaseLinks:
        case CampaignSteps.StreamReleaseLinks:
          return CampaignSteps.Setup;
        case CampaignSteps.PresaveReleaseLinks:
          return CampaignSteps.PresavePrereleaseLinks;
        case CampaignSteps.Release:
          return fields.campaignType === 'presave'
            ? CampaignSteps.PresaveReleaseLinks
            : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.Preview:
          return CampaignSteps.Release;
        default:
          return currentStep;
      }
    } else {
      // Create flow
      switch (currentStep) {
        case CampaignSteps.Scan:
          return null; // This is the first step
        case CampaignSteps.Setup:
          return CampaignSteps.Scan;
        case CampaignSteps.PresavePrereleaseLinks:
          return CampaignSteps.Setup;
        case CampaignSteps.PresaveReleaseLinks:
          return CampaignSteps.PresavePrereleaseLinks;
        case CampaignSteps.StreamReleaseLinks:
          return CampaignSteps.Setup;
        case CampaignSteps.Release:
          return fields.campaignType === 'presave'
            ? CampaignSteps.PresaveReleaseLinks
            : CampaignSteps.StreamReleaseLinks;
        case CampaignSteps.Preview:
          return CampaignSteps.Release;
        default:
          return currentStep;
      }
    }
  };

  const onSubmit = async () => {
    if (!enableSubmit || !artistHandle) return;

    setIsSubmitting(true);

    const input = {
      artistHandle,
      announcement:
        fields.announcementDate && fields.message && fields.shouldSendSms
          ? {
              date: fields.announcementDate.toISOString(),
              message: fields.message,
            }
          : undefined,
      contentType: fields.contentType,
      coverImageMediaId: fields.image && fields.mediaId ? fields.mediaId : undefined,
      thirdPartyReleaseImageUrl: fields.thirdPartyImageUrl,
      description: fields.description || undefined,
      externalLinks: fields.dsps
        .filter(dsp => dsp.uri !== '')
        .map((dsp, index) => ({
          cta:
            dsp.buttonText ||
            (dsp.buttonText === '' || dsp.buttonText === null
              ? fields.campaignType === CampaignType.Presave
                ? 'Presave'
                : 'Play'
              : dsp.buttonText),
          enabled: dsp.showLink,
          order: index,
          platform: dsp.key,
          url: dsp.uri,
        })),
      presavesEnabled: fields.campaignType === CampaignType.Presave,
      releaseDate: fields.releaseDate.toISOString(),
      releaseMessage: fields.shouldSendSms ? fields.message : undefined,
      title: fields.title,
      appleMusicResourceId: extractAppleMusicResourceId(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.AppleMusic)?.uri,
      ),
      spotifyResourceId: extractSpotifyResourceId(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.Spotify)?.uri,
      ),
    };

    try {
      const { data } = await createEvent({ input });

      if (data.createReleaseCampaign.__typename === 'ValidationError') {
        openToast({
          text: data.createReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign - Validation Error', {
          level: 'error',
        });
      }

      if (data.createReleaseCampaign.__typename === 'NotFoundError') {
        openToast({
          text: data.createReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign - Not Found', {
          level: 'error',
        });
      }

      if (data.createReleaseCampaign.__typename === 'MutationCreateReleaseCampaignSuccess') {
        trackEvent({
          type: EVENTS.CREATE_RELEASE_CAMPAIGN,
          properties: {
            artistHandle,
            presaveEnabled: fields.campaignType === CampaignType.Presave,
          },
        });
        navigate(artistNavigationPath(artistHandle, '/'));
        openBottomsheet({
          type: BOTTOMSHEET_TYPES.EVENT_CREATE_SUCCESS,
          shared: {
            withVaultTheme: true,
          },
          eventCreateSuccessBottomsheetProps: {
            campaignId: data.createReleaseCampaign.data.id,
          },
        });
        setTimeout(() => {
          clearAllFields();
        }, 5000);
      }
    } catch (error) {
      captureException(error, { tags: { feature: 'Create Release Campaign', artistHandle } });
      openToast({
        text: 'There was an error creating the event',
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdate = async (releaseCampaignId: string) => {
    if (!enableSubmit || !artistHandle) return;

    setIsSubmitting(true);

    const input = {
      releaseCampaignId,
      artistHandle,
      announcement:
        fields.announcementDate &&
        fields.message &&
        fields.shouldSendSms &&
        !fields.isAnnouncementDatePassed
          ? {
              date: fields.announcementDate.toISOString(),
              message: fields.message,
            }
          : undefined,
      contentType: fields.contentType,
      coverImageMediaId: fields.image && fields.mediaId ? fields.mediaId : undefined,
      description: fields.description || undefined,
      externalLinks: fields.dsps
        .filter(dsp => dsp.uri !== '')
        .map((dsp, index) => ({
          cta:
            dsp.buttonText ||
            (dsp.buttonText === '' || dsp.buttonText === null
              ? fields.campaignType === CampaignType.Presave
                ? 'Presave'
                : 'Play'
              : dsp.buttonText),
          enabled: dsp.showLink,
          order: index,
          platform: dsp.key,
          url: dsp.uri,
        })),
      releaseDate: fields.releaseDate.toISOString(),
      releaseMessage: fields.shouldSendSms ? fields.message : undefined,
      title: fields.title,
      appleMusicResourceId: extractAppleMusicResourceId(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.AppleMusic)?.uri,
      ),
      spotifyResourceId: extractSpotifyResourceId(
        fields.dsps.find(dsp => dsp.key === ThirdPartyPlatform.Spotify)?.uri,
      ),
    };

    try {
      const { data } = await updateEvent({ input });

      if (data.updateReleaseCampaign.__typename === 'ValidationError') {
        openToast({
          text: data.updateReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign - Validation Error', {
          level: 'error',
        });
      }

      if (data.updateReleaseCampaign.__typename === 'NotFoundError') {
        openToast({
          text: data.updateReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign - Not Found', {
          level: 'error',
        });
      }

      if (data.updateReleaseCampaign.__typename === 'MutationUpdateReleaseCampaignSuccess') {
        trackEvent({
          type: EVENTS.UPDATE_RELEASE_CAMPAIGN,
          properties: {
            artistHandle,
            presaveEnabled: fields.campaignType === CampaignType.Presave,
          },
        });
        navigate(artistNavigationPath(artistHandle, '/'));
        openToast({
          text: 'Event successfully updated.',
          variant: 'success',
        });
        setTimeout(() => {
          clearAllFields();
        }, 5000);
      }
    } catch (error) {
      captureException(error, { tags: { feature: 'Update Release Campaign', artistHandle } });
      openToast({
        text: 'There was an error updating the event',
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadCampaignImage = async (artistId: string, file: File) => {
    setIsUploading(true);
    try {
      const { mediaId, cdnUrl } = await uploadMultipartFile({
        file,
        mediaType: 'IMAGE',
        setProgress: bytes => {
          const progress = Math.floor((bytes / file.size) * 100);
          setProgress(progress);
        },
        artistId,
      });
      setField('mediaId', mediaId);
      setField('image', cdnUrl);
    } catch (error) {
      captureException(error, {
        tags: {
          selectedFileName: file.name,
          selectedFileSize: file.size,
          selectedFileType: file.type,
          feature: 'useCampaignForm',
        },
      });
      openToast({
        text: `There was an error uploading your image. ${error}`,
        variant: 'error',
      });
    } finally {
      setProgress(0);
      setIsUploading(false);
    }
  };

  return {
    fields,
    errors,
    enableSubmit,
    isUploading,
    isSubmitting,
    progress,
    isSetupComplete,
    isLinksComplete,
    isReleaseComplete,
    releaseCampaignCreationLoading,
    releaseCampaignUpdateLoading,
    determineNextStep,
    determinePrevStep,
    uploadCampaignImage,
    onSubmit,
    onUpdate,
    clearFields: clearAllFields,
    clearErrors,
    setField,
    populateFields,
    validateField,
    setError,
  };
};
