import { formatDate } from 'date-fns';
import { gql } from '@soundxyz/gql-string';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  type FragmentType,
  getFragment,
  PlayStreamReceiptItemFragmentDoc,
} from '../../graphql/generated';
import { ReceiptUI } from './ReceiptUI';

gql(/* GraphQL */ `
  fragment PlayStreamReceiptItem on ArtistMembershipReceipt {
    __typename
    id
    createdAt
    serialNumber
    membership {
      serialNumber
    }
    user {
      id
      username
      displayName
    }
    artist {
      id
      name
      linkValue
    }
    ... on ArtistMembershipPlayStreamReceipt {
      releaseCampaign {
        id
        title
      }
    }
  }
`);

export const ReceiptItem = ({
  receiptData,
}: {
  receiptData: FragmentType<PlayStreamReceiptItemFragmentDoc>;
}) => {
  const receipt = getFragment(PlayStreamReceiptItemFragmentDoc, receiptData);
  const { openBottomsheet } = useBottomsheetContainer();

  const isStreamReceipt = receipt.__typename === 'ArtistMembershipPlayStreamReceipt';
  if (!isStreamReceipt) {
    return null;
  }

  const {
    serialNumber: receiptNumber,
    createdAt,
    user: { username, displayName },
    releaseCampaign: { title },
    artist: { linkValue: artistHandle },
    membership: { serialNumber: memberId },
  } = receipt;

  const receiptItemData = {
    title,
    receiptNumber,
    username: displayName ?? username ?? 'username',
    memberId,
    artistHandle,
    createdAt,
  };
  return (
    <View
      className="flex h-16 w-full cursor-pointer justify-start gap-4 transition-all duration-200 ease-in hover:opacity-80"
      onClick={() => {
        openBottomsheet({
          type: BOTTOMSHEET_TYPES.RECEIPT,
          receiptBottomSheetProps: {
            id: receipt.id,
            type: isStreamReceipt ? 'streaming' : 'pre-save',

            ...receiptItemData,
          },
        });
      }}
    >
      <View className="aspect-square h-[52px] w-16 flex-shrink-0 overflow-hidden rounded-md bg-vault_text/10 pt-3">
        <div className="scale-receipt-sm">
          <ReceiptUI {...receiptItemData} type={isStreamReceipt ? 'streaming' : 'pre-save'} />
        </div>
      </View>
      <View className="flex flex-col justify-center gap-2">
        <Text className="max-w-56 flex-shrink overflow-hidden text-ellipsis whitespace-nowrap font-title text-[18px]/[20px] font-medium text-vault_text">
          {title}
        </Text>
        <Text className="text-[12px] text-vault_text/50">
          {formatDate(createdAt, 'MMM d, Y· p')}
        </Text>
      </View>
    </View>
  );
};

export const ReceiptItemSkeleton = () => (
  <View className="flex h-20 w-full items-center justify-center gap-3">
    <View className="flex h-16 w-full gap-4">
      <View className="flex  aspect-square w-16 flex-shrink-0 animate-pulse items-center justify-center rounded-md bg-vault_text/10" />
      <View className="flex w-full flex-col justify-center gap-2 ">
        <View className="h-5 w-[38%] animate-pulse rounded-sm bg-vault_text/10" />
        <View className="flex flex-col gap-1">
          <View className="h-3 w-[20%] animate-pulse rounded-sm bg-vault_text/10" />
        </View>
      </View>
    </View>
  </View>
);
