import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import { UserArtistReceiptsDocument } from '../../graphql/generated/documents';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  query UserArtistReceipts($artistHandle: String!, $after: String, $first: Int!) {
    userArtistMembershipReceipts(artistHandle: $artistHandle, first: $first, after: $after) {
      edges {
        node {
          __typename
          id
          ...PlayStreamReceiptItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useUserArtistReceipts = ({
  artistHandle,
  limit = 15,
  source,
}: {
  artistHandle?: string;
  limit?: number;
  source: 'receipts_page' | 'membership_page';
}) => {
  const { loginStatus } = useAuthContext();
  const {
    orderedList: userArtistReceipts,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  } = useInfiniteQuery(UserArtistReceiptsDocument, {
    filterQueryKey: { artistHandle, source },
    staleTime: 0,
    enabled: !!artistHandle && loginStatus === LoginStatus.LOGGED_IN,
    getNextPageParam: ({ data }) => {
      return (
        data.userArtistMembershipReceipts.pageInfo.hasNextPage && {
          after: data.userArtistMembershipReceipts.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
        };
      }),

    list: ({ userArtistMembershipReceipts }) => {
      return userArtistMembershipReceipts.edges.map(edge => edge.node);
    },
    uniq: ({ id }) => id,
  });

  return {
    userArtistReceipts,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  };
};
