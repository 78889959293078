import { useCallback, useState } from 'react';
import { captureException } from '@sentry/react';

import { PlayerType } from '../../audio/AudioController';
import { useSpotifyContext } from '../../contexts/SpotifyContext';
import { ReleaseCampaignContentType } from '../../graphql/generated';
import { SpotifyErrorSchema } from '../../schemas/spotify';
import { useSpotifyAccessToken } from './useSpotifyAccessToken';

export function usePlaySpotifyTrack({
  spotifyUri,
  campaignId,
  contentType,
}: {
  spotifyUri: string | null | undefined;
  campaignId: string | null | undefined;
  contentType: ReleaseCampaignContentType | null | undefined;
}) {
  const { device, playbackState, player, setActiveCampaignId } = useSpotifyContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { accessToken } = useSpotifyAccessToken();

  const isPlayingSong = playbackState != null && playbackState.context.uri === spotifyUri;

  const onClick = useCallback(async () => {
    if (
      device == null ||
      accessToken == null ||
      spotifyUri == null ||
      campaignId == null ||
      contentType == null
    )
      return;

    setActiveCampaignId(campaignId);

    PlayerType.current = 'spotify';

    if (isPlayingSong && player != null) {
      player.togglePlay();
      return;
    }

    setIsLoading(true);

    const body =
      contentType === ReleaseCampaignContentType.Album
        ? { context_uri: spotifyUri }
        : { uris: [spotifyUri] };

    await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${device.device_id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async resp => {
        if (resp.ok) {
          setIsError(false);
        } else {
          setIsError(true);
          const error = SpotifyErrorSchema.safeParse(await resp.json().catch(() => null));
          if (error.success) {
            captureException(error.data, {
              tags: {
                feature: 'usePlaySpotifyTrack',
              },
              extra: {
                spotifyUri,
                deviceId: device.device_id,
              },
            });
          }
        }
      })
      .catch(error => {
        setIsError(true);
        captureException(error, {
          tags: {
            feature: 'usePlaySpotifyTrack',
          },
          extra: {
            spotifyUri,
            deviceId: device.device_id,
          },
        });
      });

    setIsLoading(false);
  }, [
    accessToken,
    campaignId,
    contentType,
    device,
    isPlayingSong,
    player,
    setActiveCampaignId,
    spotifyUri,
  ]);

  return {
    onClick,
    isLoading,
    isError,
    isDisabled:
      device == null ||
      accessToken == null ||
      spotifyUri == null ||
      campaignId == null ||
      contentType == null,
  };
}
