import { captureException } from '@sentry/react';
import download from 'downloadjs';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation } from '../../../graphql/client';
import { SignedTrackDownloadUrlByTrackIdDocument } from '../../../graphql/generated';
import { useStableCallback } from '../../../hooks/useStableCallback';

gql(/* GraphQL */ `
  query SignedTrackDownloadUrlByTrackId($trackContentId: UUID!) {
    getSignedTrackDownloadContent(trackContentId: $trackContentId) {
      __typename
      ... on Error {
        message
      }
      ... on QueryGetSignedTrackDownloadContentSuccess {
        data
      }
    }
  }
`);

export const useTrackDownloadPress = ({ trackContentId }: { trackContentId: string }) => {
  const { mutateAsync: fetchDownloadUrl } = useMutation(SignedTrackDownloadUrlByTrackIdDocument, {
    retry: 3,
  });

  const { openToast } = useToast();

  const onDownloadClick = useStableCallback(async () => {
    try {
      const { data } = await fetchDownloadUrl({
        trackContentId,
      });

      if (
        data.getSignedTrackDownloadContent.__typename ===
        'QueryGetSignedTrackDownloadContentSuccess'
      ) {
        download(data.getSignedTrackDownloadContent.data);
      } else {
        openToast({
          text: data.getSignedTrackDownloadContent.message,
          variant: 'error',
        });
      }
    } catch (e) {
      captureException(e, {
        extra: {
          trackContentId,
        },
      });
      openToast({
        text: 'Error fetching download url. Please try again later.',
        variant: 'error',
      });
    }
  });

  return { onDownloadClick };
};
