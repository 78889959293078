import { createContext, useContext } from 'react';
import type { QueryObserverResult } from '@tanstack/react-query';
import { proxy } from 'valtio';
import type { ExecutionResultWithData } from '@soundxyz/graphql-react-query';
import type {
  ArtistAdminRole,
  AuthUserFragment,
  AuthUserQuery,
  VaultType,
} from '../graphql/generated';
import { LoginStatus } from '../types/authTypes';

export type AuthContextType = {
  loggedInUser?:
    | (AuthUserFragment & {
        artist: {
          id: string;
          name: string;
          mainLinkValue: string;
          artistLinks: string[];
          mainVaultId: string;
          profileImage: { id: string; url: string } | null;
          role: ArtistAdminRole | null;
          mainVaultType: VaultType;
        } | null;
      })
    | null;
  isArtist: boolean;
  isTestPhoneUser: boolean;
  isOfficialVaultUser: boolean;
  authError: Error | null;
  loginStatus: LoginStatus;
  refetchAuthUser: () =>
    | Promise<QueryObserverResult<ExecutionResultWithData<AuthUserQuery>>>
    | undefined;
};

const defaultAuthContext: AuthContextType = {
  loggedInUser: null,
  isArtist: false,
  isTestPhoneUser: false,
  isOfficialVaultUser: false,
  authError: null,
  loginStatus: LoginStatus.LOADING,
  refetchAuthUser() {
    throw Error('Auth not initialized');
  },
};

export const GlobalAuthContext = proxy(defaultAuthContext);

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

const useAuthContext = () => useContext(AuthContext);

export { AuthContext, useAuthContext };
