import { proxy, ref, useSnapshot } from 'valtio';
import { VaultContentAccessFeatureInput } from '../graphql/generated';

export const TrackUpload = proxy<{
  isUploadingTrack: boolean;
  isUploadingTrackPreview: boolean;
  isUploadingVideoPreview: boolean;
  featureAccessType: VaultContentAccessFeatureInput;
  title: string;
  caption: string;
  notificationMessage: string | null;
  totalSize: number;
  progressSize: number;
  isPromptOpen: boolean;
  shouldSendSms: boolean;
  fileRef: {
    current:
      | ({
          file: File;
          vaultId: string;
          objectUrl: string;
          folderId: string | null;
        } & (
          | { contentType: 'TRACK'; duration: number; normalizedPeaks: number[] }
          | { contentType: 'VIDEO'; duration: number; normalizedPeaks: undefined }
          | { contentType: 'IMAGE'; duration: undefined; normalizedPeaks: undefined }
        ))
      | null;
  };
  retryUpload: (() => void) | null;
  downloadEnabled: boolean;
}>({
  isUploadingTrack: false,
  isUploadingTrackPreview: false,
  isUploadingVideoPreview: false,
  featureAccessType: VaultContentAccessFeatureInput.PaidVaultContent,
  shouldSendSms: true,
  title: '',
  caption: '',
  notificationMessage: null,
  totalSize: 0,
  progressSize: 0,
  isPromptOpen: false,
  fileRef: ref({
    current: null,
  }),
  retryUpload: null,
  downloadEnabled: false,
});

export const useTrackUpload = () => useSnapshot(TrackUpload, { sync: true });
