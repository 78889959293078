import React, { useEffect } from 'react';
import { produce } from 'immer';
import { useLocation, useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { resetActiveTrack } from '../../audio/AudioController';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { FinalizeTrackUploadView } from '../../components/views/FinalizeTrackUploadView';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';
import { TrackUpload, useTrackUpload } from '../../contexts/TrackUploadContext';
import { useMutation } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistByHandleDocument,
  CreateVaultContentDocument,
  MediaType,
  VaultContentAccessFeatureInput,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
  VaultContentPaginationDocument,
  VaultContentType,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useVaultContentByFolderPosition } from '../../hooks/useVaultContent';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { Sentry } from '../../sentry';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { uploadMultipartFile } from '../../utils/s3Utils';

RefetchOnComplete({
  trigger: [CreateVaultContentDocument],
  refetch: [
    ArtistByHandleDocument,
    VaultContentPaginationDocument,
    VaultContentByFolderDocument,
    VaultContentByFolderPositionDocument,
  ],
});

gql(/* GraphQL */ `
  mutation CreateVaultContent($input: MutationCreateVaultContentInput!) {
    createVaultContent(input: $input) {
      __typename
      ... on MutationCreateVaultContentSuccess {
        data {
          __typename
          id
          title
          createdAt
          featureAccess {
            feature {
              __typename
            }
          }
          folderPosition
          isFullVersionAvailable
          ...TrackFileInfo
          ...VaultItem
        }
      }
    }
  }
`);

export function FinalizeTrackUploadPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { title, caption, notificationMessage, featureAccessType, shouldSendSms, downloadEnabled } =
    useTrackUpload();

  const { value: membershipV2Enabled } = useGate(FEATURE_GATES.MEMBERSHIP_V2);

  const { artistHandle } = useArtistHandle();
  const { fileRef, isPromptOpen } = useTrackUpload();

  useVaultTheme();

  const musicMedia = fileRef.current;

  const { setInfiniteQueryData } = useVaultContentByFolderPosition({
    vaultId: musicMedia?.vaultId,
    folderId: musicMedia?.folderId,
  });

  const { mutateAsync: createVaultContent } = useMutation(CreateVaultContentDocument, {
    retry: 3,
  });

  useEffect(() => {
    if (!artistHandle) {
      navigate(ROUTES.NOT_FOUND);
      return;
    }

    if (!musicMedia && !isPromptOpen) {
      if (
        (window.history.state.idx != null && window.history.state.idx > 0) ||
        (window.history?.length && window.history.length > 1)
      ) {
        navigate(-1);
      } else {
        navigate(artistNavigationPath(artistHandle, '/'));
      }
    }
  }, [artistHandle, musicMedia, navigate, isPromptOpen]);

  const { openToast } = useToast();

  useEffect(() => {
    return () => {
      resetActiveTrack();
    };
  }, []);

  const { loggedInUser } = useAuthContext();

  const onShareClick = useStableCallback(async () => {
    if (!musicMedia) return;

    if (artistHandle != null) {
      trackEvent({
        type: EVENTS.UPLOAD_TRACK,
        properties: { vaultId: musicMedia.vaultId, artistHandle: artistHandle.toLowerCase() },
        pathname,
      });
    }

    const folderId = musicMedia.folderId;

    if (
      (window.history.state.idx != null && window.history.state.idx > 0) ||
      (window.history?.length && window.history.length > 1)
    ) {
      navigate(-1);
    } else {
      navigate(
        artistNavigationPath(
          artistHandle,
          folderId != null ? `/folder/${folderId}` : membershipV2Enabled ? '/dashboard' : '/',
        ),
      );
    }

    uploadTrack();

    async function uploadTrack() {
      TrackUpload.retryUpload = null;
      if (!musicMedia) return;

      TrackUpload.isUploadingTrack = true;

      TrackUpload.progressSize = 0;

      try {
        const { mediaId } = await uploadMultipartFile({
          file: musicMedia.file,
          setProgress(bytes) {
            TrackUpload.progressSize = bytes;
          },
          mediaType:
            musicMedia.contentType === 'TRACK'
              ? MediaType.Audio
              : musicMedia.contentType === 'IMAGE'
                ? MediaType.Image
                : MediaType.Video,
          artistId: loggedInUser?.artist?.id,
        });

        const { data } = await createVaultContent({
          input: {
            mediaId,
            vaultId: musicMedia.vaultId,
            title: title.trim() || null,
            caption: caption.trim() || null,
            notificationMessage: notificationMessage?.trim() || null,
            duration: musicMedia.duration,
            normalizedPeaks: musicMedia.normalizedPeaks,
            uniqueId: mediaId,
            featureAccess: featureAccessType,
            parentVaultContentId: musicMedia.folderId,
            contentType: musicMedia.contentType,
            shouldSendSms,
            downloadEnabled,
          },
        });

        if (data.createVaultContent?.__typename !== 'MutationCreateVaultContentSuccess') {
          switch (data.createVaultContent?.__typename) {
            case 'ValidationError': {
              openToast({
                text: 'Invalid media could not be added',
                variant: 'error',
              });
              return;
            }
            default: {
              openToast({
                text: 'An unexpected error occurred. Please try uploading again later.',
                variant: 'error',
              });
              return;
            }
          }
        }

        const node = data.createVaultContent.data;

        setInfiniteQueryData(previousData => {
          if (!previousData) {
            return {
              pageParams: [],
              pages: [
                {
                  data: {
                    vaultContentByFolderPosition: {
                      edges: [
                        {
                          cursor: node.id,
                          node,
                        },
                      ],
                      pageInfo: {
                        hasNextPage: true,
                        endCursor: null,
                      },
                    },
                  },
                },
              ],
            };
          }

          return produce(previousData, draft => {
            draft.pages[0]?.data.vaultContentByFolderPosition.edges.unshift({
              cursor: node.id,
              node,
            });
          });
        });

        openToast({
          text: `Media uploaded successfully. ${
            musicMedia.contentType === VaultContentType.Track
              ? 'Your snippet video will be available in ~3 minutes.'
              : ''
          }`,
          variant: 'success',
        });

        TrackUpload.fileRef.current = null;
        TrackUpload.title = '';
        TrackUpload.shouldSendSms = true;
        TrackUpload.caption = '';
        TrackUpload.notificationMessage = null;
        TrackUpload.featureAccessType = VaultContentAccessFeatureInput.PaidVaultContent;
        TrackUpload.progressSize = 0;
        TrackUpload.totalSize = 0;
        TrackUpload.downloadEnabled = false;
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            ...TrackUpload,
          },
          tags: {
            type: 'uploadTrackMutation',
          },
        });
        TrackUpload.retryUpload = uploadTrack;
      } finally {
        TrackUpload.isUploadingTrack = false;
      }
    }
  });

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      stretch
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      headerLeft={
        <BackButton
          onClick={() => {
            navigate(artistNavigationPath(artistHandle, '/upload'), { replace: true });
          }}
          className="text-vault_text"
        />
      }
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Text members</Text>
      }
      contentClassName="md2:bg-vault_text/3"
    >
      <FinalizeTrackUploadView onShareClick={onShareClick} />
    </DefaultLayout>
  );
}
