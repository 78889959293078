import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { startCase, toLower } from 'lodash-es';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { uuidv4 } from '@soundxyz/utils';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  type FragmentType,
  getFragment,
  MusicCampaignViewFragmentDoc,
  ThirdPartyPlatform,
} from '../../graphql/generated';
import { useAppleMusicAuth } from '../../hooks/appleMusic/useAppleMusicAuth';
import { useSpotifyAuth } from '../../hooks/spotify/useSpotifyAuth';
import { useIsClamped } from '../../hooks/useIsClamped';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { DSPRow } from './DSPRow';
import { type CampaignState, CampaignType } from './schema';

gql(/* GraphQL */ `
  fragment MusicCampaignView on ReleaseCampaign {
    id
    linkValue
    presavesEnabled
    status
    title
    externalLinks {
      id
      cta
      enabled
      platform
      url
    }
    coverImage {
      id
      url
    }
    initialReleaseImageUrl
    description
    artist {
      id
      name
      linkValue
      profileImage {
        id
        url
      }
    }
  }
`);

export function MusicCampaignView(
  props:
    | {
        campaignFrag: FragmentType<MusicCampaignViewFragmentDoc>;
        isPreview: false;
      }
    | {
        fields: CampaignState['fields'];
        isPreview: true;
      },
) {
  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);
  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);

  const { isPreview } = props;

  const { loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const campaign = isPreview ? null : getFragment(MusicCampaignViewFragmentDoc, props.campaignFrag);

  const spotifyAuth = useSpotifyAuth({
    presaveEnabled: spotifyConnectEnabled,
  });

  const isSpotifyAccountLinked = !!loggedInUser?.spotifyAuthConnection?.spotifyUserId;
  const isSpotifyAccountConnected = spotifyAuth.type === 'already-connected';

  const appleMusicAuth = useAppleMusicAuth({
    appleMusicEnabled: appleMusicConnectEnabled,
  });

  const isAppleMusicAccountLinked = !!loggedInUser?.appleMusicAuthConnections.length;
  const isAppleMusicAccountConnected =
    appleMusicAuth.type === 'apple-music-already-linked' ||
    appleMusicAuth.type === 'apple-music-connected-without-user';

  const { openBottomsheet } = useBottomsheetContainer();

  const artistName = useMemo(() => {
    return isPreview
      ? loggedInUser?.artist?.name || loggedInUser?.artist?.mainLinkValue
      : campaign?.artist.name || campaign?.artist.linkValue;
  }, [
    campaign?.artist.linkValue,
    campaign?.artist.name,
    isPreview,
    loggedInUser?.artist?.mainLinkValue,
    loggedInUser?.artist?.name,
  ]);

  const artistImageUrl = useMemo(() => {
    return isPreview ? loggedInUser?.artist?.profileImage?.url : campaign?.artist.profileImage?.url;
  }, [campaign?.artist.profileImage?.url, isPreview, loggedInUser?.artist?.profileImage?.url]);

  const artistLinkValue = isPreview ? undefined : campaign?.artist.linkValue;

  const coverImageUrl = useMemo(() => {
    return isPreview
      ? props.fields.image || props.fields.thirdPartyImageUrl
      : campaign?.coverImage?.url || campaign?.initialReleaseImageUrl;
  }, [campaign?.coverImage?.url, campaign?.initialReleaseImageUrl, isPreview, props]);

  const title = isPreview ? props.fields.title : campaign?.title;

  const description = isPreview ? props.fields.description : campaign?.description;

  const [isTruncatedDescription, setIsTruncatedDescription] = useState(true);
  const toggleTruncated = useCallback(() => {
    setIsTruncatedDescription(value => !value);
  }, []);
  const textRef = useRef<HTMLDivElement>(null);
  const { isClamped } = useIsClamped(textRef);

  const randomUUID = useMemo(() => uuidv4(), []);

  const background = useMemo(() => {
    return (
      <>
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Blurred Track/Album Cover"
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        ) : (
          <UserPlaceholderImage
            id={randomUUID}
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        )}

        <View
          className={twMerge(
            'absolute bottom-0 z-base hidden h-full w-full bg-gradient-to-b from-transparent to-black',
            !isPreview && 'md2:block',
          )}
        />
        <View
          className={twMerge(
            'absolute bottom-0 z-base h-full w-full bg-gradient-to-b from-transparent via-black/80 to-black',
            !isPreview && 'md2:hidden',
          )}
        />
      </>
    );
  }, [coverImageUrl, isPreview, randomUUID]);

  const links = useMemo(() => {
    if (isPreview) {
      return props.fields.dsps
        .filter(
          dsp =>
            dsp.showLink &&
            dsp.uri &&
            (props.fields.campaignType === CampaignType.Presave
              ? dsp.key === ThirdPartyPlatform.Spotify || dsp.key === ThirdPartyPlatform.AppleMusic
              : true),
        )
        .map(dsp => (
          <DSPRow
            key={dsp.key}
            type={props.fields.campaignType ?? CampaignType.Presave}
            dspInfo={{ ...dsp, key: dsp.key }}
            isPreview
          />
        ));
    }

    const result = campaign?.externalLinks
      .filter(
        externalLink =>
          externalLink.enabled &&
          (campaign?.presavesEnabled
            ? externalLink.platform === ThirdPartyPlatform.Spotify ||
              externalLink.platform === ThirdPartyPlatform.AppleMusic
            : true),
      )
      .map(externalLink => {
        return (
          <DSPRow
            key={externalLink.id}
            type={campaign?.presavesEnabled ? CampaignType.Presave : CampaignType.Stream}
            dspInfo={{
              buttonText: externalLink.cta || (campaign?.presavesEnabled ? 'Presave' : 'Play'),
              key: externalLink.platform,
              name: startCase(toLower(externalLink.platform.replace('/_/', ' '))),
              showLink: true,
              uri: externalLink.url,
            }}
            isPreview={false}
          />
        );
      });

    return result ?? [];
  }, [isPreview, campaign?.externalLinks, campaign?.presavesEnabled, props]);

  const { hasSpotifyLink, hasAppleMusicLink } = useMemo(() => {
    return {
      hasSpotifyLink: links.some(link => link.props.dspInfo.key === ThirdPartyPlatform.Spotify),
      hasAppleMusicLink: links.some(
        link => link.props.dspInfo.key === ThirdPartyPlatform.AppleMusic,
      ),
    };
  }, [links]);

  const hasPlatformConnected = useMemo(() => {
    const spotifyConnected =
      hasSpotifyLink &&
      (isSpotifyAccountLinked || isSpotifyAccountConnected) &&
      spotifyAuth.authCode;

    const appleMusicConnected =
      hasAppleMusicLink &&
      (isAppleMusicAccountConnected || isAppleMusicAccountLinked) &&
      appleMusicAuth.userToken;

    return spotifyConnected || appleMusicConnected;
  }, [
    hasSpotifyLink,
    hasAppleMusicLink,
    isSpotifyAccountLinked,
    isSpotifyAccountConnected,
    spotifyAuth.authCode,
    isAppleMusicAccountConnected,
    isAppleMusicAccountLinked,
    appleMusicAuth.userToken,
  ]);

  const showConnectSection = isPreview
    ? props.fields.campaignType === CampaignType.Presave
    : !campaign?.presavesEnabled;

  const showContentSection = links.length > 0 || showConnectSection;

  const getPaddingTop = useMemo(() => {
    const paddingValuesWithConnect = ['pt-[20vh]', 'pt-[15vh]', 'pt-[10vh]', 'pt-[5vh]'];
    const paddingValuesWithoutConnect = [
      'pt-[30vh]',
      'pt-[25vh]',
      'pt-[20vh]',
      'pt-[10vh]',
      'pt-[5vh]',
    ];

    const paddingArray = showConnectSection
      ? paddingValuesWithConnect
      : paddingValuesWithoutConnect;

    const index = Math.max(0, Math.min(links.length - 1, paddingArray.length - 1));

    return paddingArray[index] || (showConnectSection ? 'pt-[20vh]' : 'pt-[30vh]');
  }, [links.length, showConnectSection]);

  useEffect(() => {
    if (
      !isPreview &&
      searchParams.get('openBottomSheet') === 'connect' &&
      !!campaign &&
      !campaign.presavesEnabled &&
      !hasPlatformConnected
    ) {
      setTimeout(() => {
        // Remove the search param
        removeSearchParam('openBottomSheet');

        openBottomsheet({
          type: 'CONNECT_STREAMING_PLATFORM',
          connectStreamingPlatformBottomSheetProps: {
            campaignId: campaign.id,
            artistHandle: campaign.artist.linkValue,
            campaignLinkValue: campaign.linkValue,
            hasSpotifyLink,
            hasAppleMusicLink,
          },
        });
      }, 500);
    }
  }, [
    campaign,
    hasAppleMusicLink,
    hasPlatformConnected,
    hasSpotifyLink,
    isPreview,
    openBottomsheet,
    searchParams,
    setSearchParams,
  ]);

  return (
    <View
      className={twMerge(
        'box-border h-full w-full items-center justify-center overflow-x-hidden',
        !isPreview && 'md2:flex',
      )}
    >
      {background}

      <View
        className={twMerge(
          'z-above1 flex h-full flex-col items-center',
          !showContentSection && 'justify-center',
          !isPreview &&
            'md2:w-2/3 md2:min-w-[825px] md2:max-w-[934px] md2:flex-row md2:justify-center md2:gap-20',
        )}
      >
        <View
          className={twMerge(
            'relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden pb-3',
            showContentSection ? getPaddingTop : 'pt-6',
            !isPreview && 'md2:flex-shrink md2:pb-6 md2:pt-6',
          )}
        >
          {coverImageUrl != null ? (
            <Image
              src={coverImageUrl}
              alt="Track/Album Cover"
              className={twMerge(
                'aspect-square w-[140px] rounded-xl object-cover',
                !isPreview && 'md2:max-h-[480px] md2:w-full md2:max-w-[480px]',
              )}
            />
          ) : (
            <UserPlaceholderImage
              id={randomUUID}
              className={twMerge(
                'aspect-square w-[140px] rounded-xl',
                !isPreview && 'md2:max-h-[480px] md2:w-full md2:max-w-[480px]',
              )}
              svgClassName="rounded-xl"
            />
          )}
        </View>

        <View
          className={twMerge(
            'z-above1 flex w-full flex-col items-center',
            !isPreview && 'md2:items-start',
          )}
        >
          <Text
            className={twMerge(
              'box-border line-clamp-2 w-full px-6 text-center text-[28px]/[34px] font-semibold text-white',
              !isPreview && 'md2:px-0 md2:text-left md2:text-[40px]/[46px]',
            )}
          >
            {title}
          </Text>

          <a
            className={twMerge(
              'mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline',
              !isPreview && 'md2:justify-start md2:px-0',
            )}
            href={
              !!artistLinkValue?.trim() ? artistNavigationPath(artistLinkValue, '/') : undefined
            }
          >
            <ArtistProfileImage
              className={twMerge('h-6 w-6', !isPreview && 'md2:h-8 md2:w-8')}
              profileImageUrl={artistImageUrl}
              withVaultTheme
            />

            <Text
              className={twMerge(
                'line-clamp-1 text-[14px]/[18px] text-white',
                !isPreview && 'md2:text-[16px]/[20px] md2:font-medium',
              )}
            >
              {artistName}
            </Text>
          </a>

          {description != null && (
            <View
              className={twMerge(
                'mt-4 box-border flex w-full cursor-pointer flex-col items-center justify-center px-6 text-center text-base400',
                !isPreview && 'md2:w-full md2:justify-start md2:px-0 md2:text-left',
              )}
              onClick={toggleTruncated}
            >
              <View
                className={clsx(
                  'w-full text-[14px]/[18px] font-normal',
                  !isPreview && 'md2:text-[16px]/[20px]',
                  isTruncatedDescription ? 'line-clamp-2' : '',
                )}
                containerRef={textRef}
              >
                {description}
              </View>

              {isTruncatedDescription && isClamped && (
                <span className="text-[14px]/[18px] font-normal text-white/50 underline">more</span>
              )}
            </View>
          )}

          {showContentSection && (
            <View
              className={twMerge(
                'mb-6 mt-6 box-border flex w-[calc(100%-48px)] flex-col gap-4 rounded-xl bg-white/10 p-6 backdrop-blur-2xl',
                !isPreview && 'md2:mb-0 md2:w-full',
              )}
            >
              {showConnectSection && (
                <View className="mb-2 flex flex-shrink-0 flex-col gap-2">
                  <Text className="text-center font-title text-[22px] font-medium text-white">
                    Are you listening?
                  </Text>
                  <Text className="text-center font-base text-[16px]/[20px] text-white/60">
                    Stream my new song and unlock
                    <br />
                    exclusive rewards, directly from me.
                  </Text>

                  <Button
                    type="primary"
                    label="Prove it"
                    className={twMerge('mt-2', links.length > 0 && 'mb-4')}
                    onClick={() => {
                      if (isPreview || !campaign?.id) return;

                      if (hasPlatformConnected) {
                        const path = artistNavigationPath(
                          artistLinkValue,
                          `/s/${campaign.linkValue}/claim`,
                          'step=view', // TODO: Change to "claim" when we have that view
                        );

                        navigate(path);
                        return;
                      }

                      openBottomsheet({
                        type: 'CONNECT_STREAMING_PLATFORM',
                        connectStreamingPlatformBottomSheetProps: {
                          campaignId: campaign.id,
                          artistHandle: campaign.artist.linkValue,
                          campaignLinkValue: campaign.linkValue,
                          hasSpotifyLink,
                          hasAppleMusicLink,
                        },
                      });
                    }}
                  />

                  {links.length > 0 && <View className="h-[1px] w-full bg-white/5" />}
                </View>
              )}

              <View
                className={twMerge(
                  'box-content flex flex-col gap-4',
                  !isPreview &&
                    'md2:scrollbar-glass md2:-mx-6 md2:max-h-[200px] md2:overflow-auto md2:px-6 md2:scrollbar-thin',
                )}
              >
                {links}
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

export const MusicCampaignSkeleton = () => {
  return (
    <View className="h-full w-full items-center justify-center overflow-hidden md2:flex">
      <View className="flex flex-col items-center pt-6 md2:h-full md2:w-2/3 md2:min-w-[825px] md2:max-w-[934px] md2:flex-row md2:gap-20 md2:pt-0">
        <LoadingSkeleton className="relative mx-auto flex aspect-square w-[140px] flex-col items-center justify-center overflow-hidden rounded-xl md2:w-full" />

        <View className="mt-8 flex w-full flex-col items-center md2:translate-y-0 md2:items-start">
          <LoadingSkeleton className="h-[30px] w-[120px]" />

          <View className="mt-2 flex items-center justify-center space-x-2">
            <LoadingSkeleton className="aspect-square h-[24px] rounded-full" />
            <LoadingSkeleton className="h-[24px] w-[100px]" />
          </View>

          <View className="mt-8 flex w-3/4 flex-row items-center justify-center text-center text-base400 md2:w-full md2:justify-start md2:text-left">
            <LoadingSkeleton className="h-[30px] w-[250px]" />
          </View>

          <LoadingSkeleton className="mt-8 flex h-[130px] w-[80vw] flex-col gap-8 rounded-xl px-6 py-8 md2:w-full" />
        </View>
      </View>
    </View>
  );
};

function removeSearchParam(param: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.replaceState({}, '', url.toString());
}
